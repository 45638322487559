import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  baseUrl: string = environment.API_SERVER;
  roleId: any;
  constructor(private _http: HttpClient) {}
  getOrganizationDetail(payload) {
    return this._http.get(this.baseUrl + `/organizations/${payload.id}/`, {
      params: payload.params,
    });
  }
  getApplications(payload) {
    return this._http.get(this.baseUrl + '/admin/applications/', {
      params: payload.params,
    });
  }

  assignApplications(payload) {
    return this._http.put(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserId}/applications/?status=true`,
      payload.body
    );
  }

  adminAssignApplications(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/app_users/${payload.appUserId}/applications/?status=true`,
      payload.body
    );
  }

  inviteExternalAppUsers(payload) {
    // tslint:disable-next-line: max-line-length
    return this._http.post(
      this.baseUrl +
        `/admin/invite_external_app_users/?roleId=${payload.rileId}&organizationId=${payload.organizationId}`,
      payload.body
    );
  }

  getOptions(payload) {
    const params = new HttpParams()
      .set('sidx', 'optionId')
      .set('sord', 'desc')
      .set('rows', '3000')
      .set('page', '1')
      .set('_search', 'true')
      .set('filters', payload.filter);
    return this._http.get(this.baseUrl + '/public/options/', {
      params: params,
    });
  }
  getCountries(payload) {
    const params = new HttpParams();
    params.set('sidx', 'countryName');
    params.set('sord', 'desc');
    params.set('rows', '3000');
    params.set('page', '1');
    params.set('_search', 'true');
    params.set('filters', '{}');
    return this._http.get(this.baseUrl + '/public/countries/', {
      params: params,
    });
  }

  getStates(payload) {
    const params = new HttpParams();
    params.set('sidx', 'stateName');
    params.set('sord', 'desc');
    params.set('rows', '3000');
    params.set('page', '1');
    params.set('_search', 'true');
    params.set('filters', payload.filter);
    // { params: new HttpParams().set('name', term) }
    return this._http.get(`${this.baseUrl}/public/states/`, { params: params });
  }

  getContractor(payload) {
    return this._http.get(
      `${this.baseUrl}/organizations/${payload.organizationId}/`
    );
  }

  getRoles(payload) {
    return this._http.get(`${this.baseUrl}/admin/roles/`, {
      params: payload.params,
    });
  }

  deleteRole(payload) {
    return this._http.delete(
      `${this.baseUrl}/admin/roles/${payload.roleName}/`
    );
  }

  updateRole(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/roles/${payload.roleName}/`,
      payload.body
    );
  }

  addRole(payload) {
    return this._http.post(`${this.baseUrl}/admin/roles/`, payload.body);
  }

  updateOrganization(payload) {
    return this._http.put(
      `${this.baseUrl}/contractors/${payload.organizationId}/`,
      payload.body
    );
  }

  assignRolePermission(payload) {
    return this._http.put(
      `${this.baseUrl}/organizations/${payload.organizationId}/roles/${payload.roleName}/permissions/`,
      payload.body
    );
  }
  updateEphytoNumber(naqsId: string, body) {
    return this._http.put(
      `${this.baseUrl}/enterprise_org/${naqsId}/`,
       body   
    );
  }
  getPermissionsForARole(payload) {
    return this._http.get(
      `${this.baseUrl}/roles/${payload.roleName}/permissions/`,
      payload.body
    );
  }

  getRolePermission(payload) {
    return this._http.get(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserId}/permissions/aggregate/roles/${payload.roleName}/permissions/`
    );
  }

  getRolePermssions(payload) {
    return this._http.get(`${this.baseUrl}/`);
  }

  getPermission(payload) {
    return this._http.get(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserId}/permissions/`
    );
  }

  getUsers(payload) {
    return this._http.get(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/`,
      {
        params: payload.params,
      }
    );
  }

  getAllUsersInAnOrganization(payload) {
    return this._http.get(`${this.baseUrl}/admin/app_users/`, {
      params: payload.params,
    });
    // return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/app_users/`, { params: payload.params });
  }

  getOrganizations(payload) {
    return this._http.get(`${this.baseUrl}/organizations/`);
  }

  inviteUser(payload) {
    return this._http.post(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserEmail}/roles/${payload.roleName}/`,
      payload.body
    );
  }

  inviteUsers(payload) {
    return this._http.post(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/?roleName=${payload.roleName}`,
      payload.body
    );
  }

  resendEmailInvitation(payload) {
    return this._http.post(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserEmail}/invitation/`,
      payload.body
    );
  }

  changeUserRole(payload) {
    return this._http.put(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserEmail}/roles/${payload.roleName}/`,
      payload.body
    );
  }

  complaintResolution(payload) {
    return this._http.put(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserId}/resolution/?status=${payload.status}`,
      payload.body
    );
  }

  changeUserStatus(payload) {
    return this._http.put(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserId}/status/?status=${payload.status}`,
      payload.body
    );
  }

  verificationStatusChange(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/app_users/${payload.appUserId}/verification_member/?status=${payload.status}`,
      payload.body
    );
  }

  removeUser(payload) {
    return this._http.delete(
      `${this.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserEmail}/`,
      payload.body
    );
  }

  deletetUser(payload) {
    return this._http.delete(
      `${this.baseUrl}/admin/app_users/${payload.appUserEmail}/`,
      payload.body
    );
  }

  changePassword(payload) {
    return this._http.put(
      `${this.baseUrl}/organizations/${payload.organizationId}/change-password/`,
      payload.body
    );
  }

  update(payload) {
    return this._http.put(
      `${this.baseUrl}/organizations/${payload.organizationId}/`,
      payload.body
    );
  }

  uploadLogoBase(payload) {
    return this._http.post(
      `${this.baseUrl}/organizations/${payload.organizationId}/logo_base64/`,
      payload.body
    );
  }
  uploadLogo(payload) {
    return this._http.post(
      `${this.baseUrl}/organizations/${payload.organizationId}/logo/`,
      payload.body
    );
  }

  changeSecondaryEmail(payload) {
    return this._http.patch(
      `${this.baseUrl}/users/${payload.appUserId}/profile/secondary_email/${payload.secondaryEmail}/`,
      payload.body
    );
  }
  changePrimaryEmail(payload) {
    return this._http.put(
      `${this.baseUrl}/users/${payload.appUserId}/profile/primary_email/`,
      payload.body
    );
  }
  changeUserName(payload) {
    return this._http.put(
      `${this.baseUrl}/users/${payload.appUserId}/profile/username/`,
      payload.body
    );
  }

  contractorAppAccess(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/contractors/${payload.organizationId}/status/${payload.status}/`,
      payload.body
    );
  }
  migrate(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/contractors/${payload.contractorId}/run_migration/?oldContractorUsername=${payload.old}`,
      ''
    );
  }

  getMdas() {
    return this._http.get(`${this.baseUrl}/public/mdas/`);
  }

  startVerification(payload) {
    return this._http.post(
      `${this.baseUrl}/admin/contractors/${payload.contractorId}/bpp_verification/`,
      payload.body
    );
  }

  finalizeVerification(payload) {
    return this._http.post(
      `${this.baseUrl}/admin/contractors/${payload.contractorId}/bpp_verification/finalize/`,
      payload.body
    );
  }

  uploadContractors(payload) {
    return this._http.post(`${this.baseUrl}/admin/contractors/`, payload.body);
  }

  editTemporaryId(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/contractors/${payload.organizationId}/`,
      payload.body
    );
  }

  exportContractorDetails(payload) {
    return this._http.get(
      `${this.baseUrl}/admin/contractors/${payload.contractorId}/bpp_verification/`,
      {
        params: payload.params,
      }
    );
  }

  createOrganization(payload) {
    return this._http.post(
      this.baseUrl + '/admin/organizations/',
      payload.body
    );
  }

  getOrganizationTypeRoles(payload) {
    return this._http.get(
      `${this.baseUrl}/admin/organization_types/payload.organizationTypeId/roles/`,
      {
        params: payload.params,
      }
    );
  }

  getOrganization(payload) {
    return this._http.get(`${this.baseUrl}/organizations/`, {
      params: payload.params,
    });
  }

  getOrganizationsAdmin(payload) {
    return this._http.get(`${this.baseUrl}/organizations/`, {
      params: payload.params,
    });
  }
  changeApiAccessStatus(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/organizations/${payload.organizationId}/api/?status=${payload.status}`,
      payload.body
    );
  }

  changeContractorStatus(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/contractors/${payload.contractorId}/bar/?status=${payload.status}`,
      payload.body
    );
  }

  addOrganizations(payload) {
    return this._http.post(
      `${this.baseUrl}/admin/organizations/`,
      payload.body
    );
  }

  updateOrganizations(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/organizations/${payload.organizationId}/`,
      payload.body
    );
  }

  deleteOrganizations(payload) {
    return this._http.delete(
      `${this.baseUrl}/admin/organizations/${payload.organizationId}/`
    );
  }

  // organization Types
  getOrganizationTypes(payload) {
    return this._http.get(`${this.baseUrl}/organizations/types/`, {
      params: payload.params,
    });
  }

  addOrganizationTypes(payload) {
    return this._http.post(
      `${this.baseUrl}/admin/organization_types/`,
      payload.body
    );
  }

  updateOrganizationTypes(payload) {
    return this._http.put(
      `${this.baseUrl}/admin/organization_types/${payload.organizationTypeId}/`,
      payload.body
    );
  }

  deleteOrganizationTypes(payload) {
    return this._http.delete(
      `${this.baseUrl}/admin/organization_types/${payload.organizationTypeId}/`
    );
  }

  exportCompaniesReport(payload) {
    return this._http.get(
      `${this.baseUrl}/organizations/${payload.organizationId}/contractors/export/`,
      {
        params: payload.params,
      }
    );
  }

  getDepartments() {
    return this._http
      .get(`${this.baseUrl}/organizations/?sord=desc&rows=100000000&page=1&_search=true&sidx=dateCreated&filters=
        %7B%22groupOp%22:%22AND%22,%22rules%22:%5B%7B%22field%22:%22organizationTypeId.organizationTypeId%22,
        %22op%22:%22eq%22,%22data%22:%22collaborator%22%7D,%7B%22field%22:%22organizationShortName%22,%22op%22:
        %22cn%22,%22data%22:%22%22%7D%5D%7D`);
  }

  getRoleFoDepartment() {
    return this._http.get(
      `${this.baseUrl}/admin/roles/?sord=desc&rows=100000000&page=1&_search=true&sidx=dateCreated`
    );
  }

  addDepartment(payload) {
    return this._http.post(`${this.baseUrl}/admin/organizations/`, payload);
  }
}
