import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize, DatepickerMode } from '@richardlt/ng2-semantic-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
interface IChatDocumentModalContext {
	data: any;
	title?: string;
}
@Component({
	selector: 'app-chat-document-modal',
	templateUrl: './chat-document-modal.component.html',
	styleUrls: [ './chat-document-modal.component.scss' ]
})
export class ChatDocumentModalComponent implements OnInit, OnDestroy {
	options = [];
	documentsType = [];
	searchable: boolean = true;
	public mode: DatepickerMode = 'year';
	public maxDate = new Date();
	private unsubscribe$: Subject<void> = new Subject<void>();
	formSubmitAttempt: boolean;
	success: any;
	error: any;
	pending: boolean;
	selectedFile;
	replyForm: FormGroup;
	documents: Array<any> = [];
	fileError;
	requireDocs = [];
	constructor(
		@Inject(DOCUMENT) private document,
		private fb: FormBuilder,
		public modal: SuiModal<IChatDocumentModalContext, void, void>
	) {
		this.documentsType = modal.context.data;
		this.replyForm = fb.group({
			documentTypeId: [ '', Validators.required ],
			documentName: [ '', Validators.required ],
			document: [ '' ]
		});
	}
	isFieldValid(field: string) {
		return (
			(!this.replyForm.get(field).valid && this.replyForm.get(field).touched) ||
			(this.replyForm.get(field).untouched && this.formSubmitAttempt)
		);
	}

	onFileChange(event) {
		const files = event.target.files;
		if (!files) {
			return;
		}
		this.selectedFile = files[0];
	}

	displayFieldCss(field: string) {
		return {
			error: this.isFieldValid(field)
		};
	}

	displayFormCss(field: string) {
		return {
			loading: field
		};
	}

	save() {
		this.formSubmitAttempt = false;
		if (this.fileError == null && this.replyForm.valid) {
			let data: any = this.replyForm.value;
			const formData: FormData = new FormData();
			formData.append('file', this.selectedFile, this.selectedFile.name);
			let payload: any = { formValue: data, formData: formData };
			this.modal.approve(payload);
		} else {
			this.formSubmitAttempt = true;
		}
	}
	nullify() {
		this.success = null;
		this.error = null;
	}
	ngOnInit() {
		// this.document.body.classList.remove('panel');
	}
	// s
	ngOnDestroy(): void {
		// this.document.body.classList.add('panel');
	}
}

export class ChatDocumentModal extends ComponentModalConfig<IChatDocumentModalContext, void, void> {
	constructor(title: string, data: any, size: ModalSize = ModalSize.Small) {
		super(ChatDocumentModalComponent, { title, data });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.transition = 'slide left';
		this.size = size;
		this.isFullScreen = false;
		this.isInverted = false;
	}
}
