import {
	Component,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	AfterViewInit,
	OnDestroy,
	Inject,
	ElementRef
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { DOCUMENT } from '@angular/common';
import { SuiModal, ComponentModalConfig, ModalSize } from '@richardlt/ng2-semantic-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppFilter } from '../../models/filter';
import { environment } from '../../../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, tap, map } from 'rxjs/operators';
import { MentionDirective } from 'angular-mentions';
import { Location } from '@angular/common';

interface IFieldCommentModalContext {
	title?: string;
	commentData?: {
		objectType?: string;
		equipmentId?: string;
	};
}
interface Comment {
	commentId?: string;
	objectId?: string;
	comment?: string;
	objectType?: string;
	documentId?: string;
	dateCreated?: string;
	createdBy?: string;
	parentCommentId?: Comment;
	documentMetadata?: string;
	parentCommentMetadata?: string;
	position?: number;
}

@Component({
	selector: 'tamsy-comment-modal',
	templateUrl: './comment-modal.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [ './comment-modal.component.scss' ]
})
export class FieldCommentModalComponent implements OnDestroy {
	@ViewChild(MentionDirective) mention: MentionDirective;
	@ViewChild('mention') div: ElementRef;
	@ViewChild('scrollMe') scrollMe: ElementRef;
	replyForm: FormGroup;
	messageParams: AppFilter;
	filterMetaData: any;
	baseUrl = environment.API_SERVER;
	searchMessagesFilter = {
		groupOp: 'AND',
		rules: [
			{
				field: 'objectId',
				op: 'eq',
				data: ''
			}
		]
	};
	private unsubscribe$: Subject<void> = new Subject<void>();
	fileObj: any;
	pageSize: any;
	pending;
	success;
	error;
	user: any;
	scrollToBottom: any;
	replyBlock;
	users: Array<any> = [
		{ appUserId: 'Appadfaflicant', name: 'Ice', username: 'Ice' },
		{ appUserId: 'adfasd', name: 'Bit', username: 'Bit' },

		{ appUserId: 'Applicant', name: 'Applicant', username: 'Applicant' }
	];
	organizationId: string;
	commentUsers;
	notifyApplicant: boolean = false;
	config = {
		triggerChar: '@',
		maxItems: 10,
		labelKey: 'username',
		mentionSelect: this.onSelected.bind(this),
		// mentionFilter: this.filterUser.bind(this),
		dropUp: true
	};
	equipmentId: string;
	comments: Array<any> = [];
	isReply: Comment = null;
	fileName: string;
	fieldId: string;
	fieldResponse: any;
	modificationType: string;
	mentionConfig$ = new BehaviorSubject<any>(this.config);
	total: number = 0;
	datasource: any;
	userMap = {};
	isContractor: boolean = false;
	newComment: boolean;
	constructor(
		public modal: SuiModal<IFieldCommentModalContext, void, void>,
		private router: Router,
		@Inject(DOCUMENT) private document,
		private fb: FormBuilder,
		private location: Location
	) {
		this.replyForm = fb.group({
			comment: [ '', Validators.required ],
			hideFromApplicant: [ true ]
		});
	}

	search(searchText) {
		let arr = [ { appUserId: 'Applicant', name: 'musa', username: 'musa' } ];
		let dat = this;
		const mentionConfig = {
			items: arr,
			triggerChar: '@',
			maxItems: 10,
			labelKey: 'username',
			mentionSelect: this.onSelected,
			// mentionFilter: this.filterUser.bind(this),
			dropUp: true
		};
		dat.mentionConfig$.next(mentionConfig);
		console.log(dat.mention);
	}

	getUsers(searchText) {
		this.users = [
			{ appUserId: 'Appadfaflicant', name: 'Ice', username: 'Ice' },
			{ appUserId: 'adfasd', name: 'Bit', username: 'Bit' },

			{ appUserId: 'Applicant', name: 'Applicant', username: 'Applicant' }
		];
	}
	onSelected(user) {
		this.userMap[user.username] = `[${user.name}](${user.appUserId})`;
		return `@${user.username}`;
	}
	onSearch(user) {
		console.log('search', user);
		this.getUsers(user);
	}
	onUserSelected(user) {
		console.log('select', user);
	}

	getPage(page) {}
	onScrollUp($event) {
		if (!this.newComment) {
			let page = this.messageParams.page + 1;
			this.getPage(page);
		}
	}

	showNewComment() {
		this.comments = [];
	}
	ngOnInit() {}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	onScrollDown($event) {}

	onReply(event) {
		this.isReply = event;
	}
	removeReply() {
		this.isReply = null;
	}
	send() {}

	sendUnlock() {}
	showReplyForm(form) {
		this.replyBlock = form;
	}

	uploadDoc($event) {
		this.fileObj = $event[0];
		this.fileName = this.fileObj['name'];
	}
}

export class FieldCommentModal extends ComponentModalConfig<IFieldCommentModalContext, void, void> {
	constructor(commentData: any, title, size: ModalSize = ModalSize.Small) {
		super(FieldCommentModalComponent, { commentData, title });

		this.isClosable = false;
		this.transitionDuration = 400;
		this.size = size;
		this.isFullScreen = false;
		this.isInverted = false;
		this.transition = 'slide left';
		this.mustScroll = false;
		// this.
	}
}
