import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../features/notification/services';
import { AppFilter } from 'src/modules/shared/models/filter';
import { debounceTime, takeUntil, distinctUntilChanged, tap, map } from 'rxjs/operators';
import { TransitionController, Transition, TransitionDirection } from '@richardlt/ng2-semantic-ui';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Notification } from 'src/modules/shared/models';
import { environment } from 'src/environments/environment';
import { USER_ADMIN } from '@app/mock';
import { AuthenticationService } from 'src/modules/core/services';
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnDestroy {
	baseUrl = environment.API_SERVER;
	currentHost = environment.currentHost;
	eServicesURL = environment.ESERVICES_URL;
	private unsubscribe$: Subject<void> = new Subject<void>();

	// this variables controls the visbilities of list and create option component (check template)
	public isCreate: boolean = false;
	public selected: boolean = true;

	public optionPopulated: boolean;
	public notifications: Notification[];
	public notification: Notification;

	public maxSize = 5;
	public unRead: Notification[];
	public read: Notification[];

	public countUnread: number;

	read_status_false: any = false;

	searchParams: AppFilter;
	optionParams: AppFilter;
	filterType: AppFilter;
	loading: boolean;
	totalUnread: number = 0;

	filter = {
		groupOp: 'AND',
		rules: [
			{
				field: 'read',
				op: 'eq',
				data: 'false'
			}
		]
	};
	//our search control
	public search = new FormControl();
	config = {
		total: 0,
		page: 1,
		records: 0
	};

	total: number = 0;
	page: number = 1;
	records: number = 0;

	searchFilter: string = '';
	organizationId: string;
	background: string;
	read_status: boolean;
	loadingScroll: boolean;
	//initial option type
	notificationSearch: Notification = {
		read: false
	};

	error: string | null;
	permissions: any[];
	user;
	photo;
	apps: Array<any> = [];
	token: string;
	constructor(
		private _searchsvc: NotificationService,
		private router: Router,
		private authSvc: AuthenticationService
	) {
		this.user = JSON.parse(window.localStorage.getItem('user'));
		this.token = window.localStorage.getItem('jwt');
		if (this.user && this.user.image) {
			this.photo = `${this.baseUrl}/public/image/?media=${this.user.image}`;
		} else {
			this.photo = 'assets/avatar/1.jpg';
		}
		this.user['applications'].forEach((app) => {
			app['applicationHost'] = app['applicationHost'] + '?token=' + this.token;
			this.apps.push(app);
		});
		// // initialize  search filter
		this.searchParams = new AppFilter({ sidx: 'notificationId', rows: 5 });
		this.searchParams.filters = JSON.stringify(this.searchFilter);
		this.optionParams = new AppFilter({ sidx: 'dateCreated', rows: 5 });
		this.optionParams.filters = JSON.stringify(this.filter);
		this.loadingScroll = false;
		this.organizationId = JSON.parse(window.localStorage.getItem('user'))['appUserId'];
		this._searchsvc.getRefreshNotification().subscribe((res) => {
			this.getPage(1);
			this.getUnreadPage(1);
		});
		this.authSvc.user.subscribe((res) => {
			console.log(res);
			if (res) {
				this.user = res;

				if (this.user && this.user.image) {
					this.photo = `${this.baseUrl}/public/image/?media=${this.user.image}`;
				} else {
					this.photo = 'assets/avatar/1.jpg';
				}
			}
		});
		this.getPage(1);
		this.getUnreadPage(1);
		if (window.localStorage.getItem('permissions')) {
			let authTokenPermission = JSON.parse(localStorage.getItem('permissions'));
			// this.permissions = JSON.parse(window.localStorage.getItem("permission"));
			this.permissions = authTokenPermission;
		}
		// this.user = USER_ADMIN.appUser;
	}

	// get page request options with filter params
	getPage(page: number) {
		this.searchParams.page = page;
		this.loading = true;
		this._searchsvc
			.getNotifications({ appUserId: this.organizationId, params: this.searchParams })
			.pipe(
				tap((res: any) => {
					this.total = +res['records'];
					this.page = page;
					this.records = +this.searchParams.rows;
				}),
				map((res: any) => res['rows']),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				(resp: Notification[]) => {
					this.loading = false;
					this.notifications = resp;
				},
				(error) => {
					this.loading = false;
					this.error = error;
				}
			);
	}

	getUnreadPage(page) {
		this.loading = true;
		this._searchsvc
			.getNotifications({ appUserId: this.organizationId, params: this.optionParams })
			.pipe(
				tap((res) => {
					this.totalUnread = +res['records'];
					this.page = page;
					this.records = +this.optionParams.rows;
				}),
				map((res) => res['rows']),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				(res: Notification[]) => {
					this.loading = false;
					this.unRead = res;
					this.checkOptionList(this.unRead);
				},
				(error) => {
					this.loading = false;
					this.error = error;
				}
			);
	}

	checkOptionList(value: Notification[]) {
		if (value != null && value.length > 0) {
			this.optionPopulated = true;
		} else {
			this.optionPopulated = false;
		}
	}

	ngOnDestroy() {
		// unsubscribe all subsciptons
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
	logout() {
		localStorage.clear();
		this.router.navigate([ '/home-page' ]);
	}
}
