// import angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SuiModule } from '@richardlt/ng2-semantic-ui';
import { MomentModule } from 'ngx-moment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MentionModule } from '../mentions/public-api';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { AppTabsModule } from '../tabs/tab.module';
import { ImageCropperModule } from 'ngx-img-cropper';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgSelectModule } from '@ng-select/ng-select';

// import components
import {
  SearchFilterComponent,
  FieldErrorDisplayComponent,
  NoRecordFound,
  CommentComponent,
  CommentNestedComponent,
  InlineEditorComponent,
  MessageToastComponent,
  EslButtonComponent,
  ErrorComponentComponent,
} from './components';
// import directives
import { IfOnDomDirective } from './directives/if-on-dom/if-on-dom.directive';
import { MarginDirective } from './directives/css-utils/margin/margin.directive';
import { PaddingDirective } from './directives/css-utils/padding/padding.directive';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { AppStatusDirective } from './directives/app-status/app-status.directive';
import {
  ClipboardDirective,
  PermissionDirective,
  BlockCopyPasteDirective,
  MyCurrencyFormatterDirective,
} from './directives';

//Modals
import {
  UploadDocumentModalComponent,
  EmailModalComponent,
  ConfirmModalComponent,
  DynamicDataDetailModalComponent,
  ChatDocumentModalComponent,
  TwoFactorModalComponent,
  FieldCommentModalComponent,
} from './modals';

//Pipes
import { FilterPipe } from './pipes/filter.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { MyDateConverterPipe } from './pipes/date';
import {
  CreatedByPipe,
  CreatedByOrgPipe,
  DataRowValuePipe,
  MapPipe,
  ParentMetaPipe,
} from './pipes';
const SHARED_COMPONENTS: Array<any> = [
  SearchFilterComponent,
  FieldErrorDisplayComponent,
  NoRecordFound,
  CommentComponent,
  CommentNestedComponent,
  InlineEditorComponent,
  MessageToastComponent,
  EslButtonComponent,
  ErrorComponentComponent,
];

const SHARED_MODULES: Array<any> = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  SuiModule,
  AppTabsModule,
  LeafletModule,
  NgxQRCodeModule,
  NgSelectModule,
  // ImageViewerModule,
  // NouisliderModule,

  InfiniteScrollModule,
  MomentModule,
  ContenteditableValueAccessorModule,
  MentionModule,
  ImageCropperModule,

  // AvatarModule
];

const SHARED_ENTRY_COMPONENTS: Array<any> = [
  ConfirmModalComponent,
  DynamicDataDetailModalComponent,
  EmailModalComponent,
  UploadDocumentModalComponent,
  ChatDocumentModalComponent,
  TwoFactorModalComponent,
  FieldCommentModalComponent,
];

const SHARED_PIPES: Array<any> = [
  DataRowValuePipe,
  TruncatePipe,
  FilterPipe,
  SortPipe,
  MyDateConverterPipe,
  CreatedByPipe,
  CreatedByOrgPipe,
  MapPipe,
  ParentMetaPipe,
];

const SHARED_DIRECTIVES: Array<any> = [
  IfOnDomDirective,
  MarginDirective,
  PaddingDirective,
  AppStatusDirective,
  ClipboardDirective,
  PermissionDirective,
  BlockCopyPasteDirective,
  MyCurrencyFormatterDirective,
];
import { DecimalPipe } from '@angular/common';
@NgModule({
  imports: [
    ...SHARED_MODULES,
    QuillModule.forRoot({
      modules: {
        // syntax: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // outdent/indent
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],

          ['clean'], // remove formatting button

          ['link', 'image', 'video'], // link and image, video
        ],
      },
    }),
  ],
  declarations: [
    ...SHARED_DIRECTIVES,
    ...SHARED_COMPONENTS,
    ...SHARED_PIPES,
    DynamicDataDetailModalComponent,
    ...SHARED_ENTRY_COMPONENTS,
  ],
  providers: [DecimalPipe],
  entryComponents: [...SHARED_ENTRY_COMPONENTS],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_PIPES,
    QuillModule,
  ],
})
export class SharedModule {}
