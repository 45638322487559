import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
// <img class="ui centered medium image" src="./assets/icons/documents.svg" />
@Component({
	selector: 'app-no-record-found',
	template: `
		<div class="ui  segment">

			<h3 class="ui primary centered header">

			{{message}}
			</h3>
			<!-- <h5 class="ui primary centered header">Click here to new {{title}}</h5> -->
		</div>
	`,
	styles: [
		`
			#action {
				border-left: 1px solid #dcdcdc !important;
			}
			.link {
				color: #4183c4;
				text-decoration: underline;
				cursor: pointer;
			}
		`
	]
})
export class NoRecordFound {
	@Input() type: string = 'items';
	@Input() title: string;
	@Input() icon: string = 'big bell icon';
	@Input() message: string = 'There are no items here';
	@Output() addNew = new EventEmitter();
	constructor() {}
}
