<h3 class="ui primary header" >{{ modal.context.title }}
<i class="link times icon" (click)="modal.deny(undefined)"></i>
</h3>
 <div class="content"  appPadding paddingY="2"   paddingT="2" >
                   <div *ngIf="success || error" appMargin marginY="10px">
                    <sui-message class="success" *ngIf="success">
                            <div class="header">
                                {{success}}
                            </div>
                        </sui-message>
                        <sui-message class="warning" *ngIf="error">
                            <div class="header">
                            {{error | json}}
                            </div>
                        </sui-message>
                    </div>



 </div>
  <div  class="actions" fxLayout="column" fxLayoutGap="10px"  >
                 <!-- <div fxFlex="row"  > -->

                         <!-- </div> -->
             <div appmargin MarginY="5"    class="ui form" [formGroup]="replyForm" >
                 <div fxFlex="row" fxLayoutGap="2px" fxLayoutAlign="space-between end" >
                            <div fxFlex>
                                <button class="ui button" (click)="uploader.click()">
                                    <i class="file outline icon"></i>
                            </button>
                            <input 
                                hidden 
                                type="file" 
                                #uploader 
                                (change)="uploadDoc($event.target.files)"
                                accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xlx,.xlsx">
                            </div>

                            <div fxFlex="grow" >
                            <div  class='content-area'
                                    #MentionDirective
                                        contenteditable
                                        [mention]="users"
                                        [(ngModel)]="commentUsers"
                                        (itemSelected)="onUserSelected($event)"
                                        [mentionConfig]="mentionConfig$ | async"
                                        (searchTerm)='search($event)'
                                    formControlName="comment"
                                    placeholder="Enter comment..."></div>
                            </div>


                            <div fxFlex >
                            <button class="ui primary button" (click)="send()">Send</button>
                        </div>
                   </div>
                   </div>
                    </div>





