import { Directive, Input, SimpleChanges, ElementRef } from '@angular/core';

@Directive({
	selector: '[appPadding]'
})
export class PaddingDirective {
	// tslint:disable-next-line:no-input-rename
	@Input('appPadding') padding: any;
	@Input() paddingX: any;
	@Input() paddingY: any;
	@Input() paddingL: any;
	@Input() paddingR: any;
	@Input() paddingT: any;
	@Input() paddingB: any;
	get left() {
		return this._parseValue(this._getValue(this.paddingL, this.paddingX, this.padding));
	}
	get right() {
		return this._parseValue(this._getValue(this.paddingR, this.paddingX, this.padding));
	}
	get top() {
		return this._parseValue(this._getValue(this.paddingT, this.paddingY, this.padding));
	}
	get bottom() {
		return this._parseValue(this._getValue(this.paddingB, this.paddingY, this.padding));
	}
	private readonly _baseSpacing = 0.25;
	constructor(private el: ElementRef) {}

	ngOnInit() {
		this._setElementMargin(this.el);
	}
	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.padding ||
			changes.paddingX ||
			changes.paddingY ||
			changes.paddingL ||
			changes.paddingR ||
			changes.paddingT ||
			changes.paddingB
		) {
			if (this.el.nativeElement) {
				this._setElementMargin(this.el);
			}
		}
	}
	private _setElementMargin(el: ElementRef) {
		const left = this.left;
		const right = this.right;
		const top = this.top;
		const bottom = this.bottom;
		if (left) {
			el.nativeElement.style.paddingLeft = left;
		}
		if (right) {
			el.nativeElement.style.paddingRight = right;
		}
		if (top) {
			el.nativeElement.style.paddingTop = top;
		}
		if (bottom) {
			el.nativeElement.style.paddingBottom = bottom;
		}
	}
	private _parseValue(value: any): string {
		if (!value && value !== 0) {
			return value;
		}
		if (this._isInt(value)) {
			return this._baseSpacing * value + 'rem';
		}
		return value;
	}
	private _getValue(priority1: any, priority2: any, priority3: any) {
		if (priority1 || priority1 === 0) {
			return priority1;
		}
		if (priority2 || priority2 === 0) {
			return priority2;
		}
		return priority3;
	}
	private _isInt(value) {
		return !isNaN(value) && ((x) => (x | 0) === x)(parseFloat(value));
	}
}
