import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

/**
 * @author Tari
 * @description
 * @dateCreated 8 Nov 2023
 */
@Component({
  selector: 'esl-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.scss'],
})
export class MessageToastComponent implements OnInit, OnChanges {
  @Input() messageType: 'success' | 'warning' | 'error' | 'info' = 'warning';
  @Input() message: any = '';
  @Output() messageChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() useJson: boolean = true;
  timeout: any;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message) {
      if (changes.message.currentValue !== changes.message.previousValue) {
        this.removeMessage();
      }
    }
  }

  /**
   * @author Tari
   * @description Remove message after 5 seconds
   * @dateCreated 8 Nov 2023
   */
  removeMessage() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.message = '';
      this.messageChange.emit(this.message);
    }, 5000);
  }
}
