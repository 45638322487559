<sui-message [class]="messageType" *ngIf="message" [isDismissable]="false">
  <!-- Display as json -->
  <div class="header" *ngIf="useJson">
    {{ message | json }}
  </div>

  <!-- Display raw string -->
  <div class="header" *ngIf="!useJson">
    {{ message }}
  </div>
</sui-message>