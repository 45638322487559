import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@richardlt/ng2-semantic-ui';
import { DOCUMENT } from '@angular/common';
interface IEmailModalContext {
	question: string;
	title?: string;
}
@Component({
	selector: 'app-email-modal',
	template: `
		<h3 id="header" class="ui primary header">
			{{ modal.context.title }}
			<i class="link times icon" (click)="modal.deny(undefined)"></i>
		</h3>

		<div class="content">
			<div class="ui basic  segment">
							<div class="ui form" appMargin marginB="20px">
				<div class="field">
				<label>Recipient Address</label>
				     <input type="text" placeholder="Enter recipient Address"/>
				</div>
				</div>
				<div class="inline text-center">
					<button class="ui  basic primary button" (click)="modal.deny(undefined)">Cancel</button>
					<button class="ui  primary button" (click)="modal.approve(undefined)" autofocus>Send</button>
				</div>
			</div>
		</div>
	`,
	styleUrls: [ './email-modal.component.scss' ]
})
export class EmailModalComponent implements OnInit, OnDestroy {
	constructor(@Inject(DOCUMENT) private document, public modal: SuiModal<IEmailModalContext, void, void>) {}
	ngOnInit() {
		this.document.body.classList.remove('panel');
	}
	// s
	ngOnDestroy(): void {
		this.document.body.classList.add('panel');
	}
}

export class EmailModal extends ComponentModalConfig<IEmailModalContext, void, void> {
	constructor(title: string, question: string, size: ModalSize = ModalSize.Small) {
		super(EmailModalComponent, { title, question });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
		this.isFullScreen = false;
		this.isInverted = false;
	}
}
