<div class="desktop-enforcer">
    <app-header></app-header>
    <div fxLayout="row" id="container">
        <div fxFlex="17%">
            <app-sidebar></app-sidebar>
        </div>
        <div fxFlex="grow" class="main" [style.background-color]="isBackground ? '#f0f1f8': '#fff'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
