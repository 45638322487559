import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppFilter } from 'src/modules/shared';
interface ReqInter {
	organizationId: string;
	params: AppFilter;
}
@Injectable({ providedIn: 'root' })
export class CompanyService {
	baseUrl: string = environment.API_SERVER;
	constructor(private _http: HttpClient) {}
	getALLCompany(payload) {
		return this._http.get(this.baseUrl + '/enterprise_org/', { params: payload.params });
	}
	getRegisteredCompany(payload) {
		return this._http.get(this.baseUrl + '/enterprise_org/', { params: payload.params });
	}
	getOldCompany(payload) {
		return this._http.get(this.baseUrl + '/old_customer/', { params: payload.params });
	}
	getOptions(payload) {
		const params = new HttpParams()
			.set('sidx', 'optionId')
			.set('sord', 'desc')
			.set('rows', '3000')
			.set('page', '1')
			.set('_search', 'true')
			.set('filters', payload.filter);
		return this._http.get(this.baseUrl + '/public/options/', { params: params });
	}
	addOldCompany(payload) {
		return this._http.post(`${this.baseUrl}/enterprise_org/`, payload.body);
	}

	updateOldCompany(payload) {
		return this._http.put(`${this.baseUrl}/enterprise_org/${payload.companyId}/`, payload.body);
	}
	removeOldCompany(payload) {
		return this._http.delete(`${this.baseUrl}/enterprise_org/${payload.companyId}/`);
	}
	getApplications(payload) {
		return this._http.get(this.baseUrl + '/admin/applications/', { params: payload });
	}
	contractorAppAccess(payload) {
		return this._http.put(
			`${this.baseUrl}/admin/contractors/${payload.organizationId}/status/${payload.status}/`,
			payload.body
		);
	}
	migrate(payload) {
		return this._http.put(
			`${this
				.baseUrl}/admin/contractors/${payload.contractorId}/run_migration/?oldContractorUsername=${payload.old}`,
			''
		);
	}

	getMdas() {
		return this._http.get(`${this.baseUrl}/public/mdas/`);
	}

	startVerification(payload) {
		return this._http.post(
			`${this.baseUrl}/admin/contractors/${payload.contractorId}/bpp_verification/`,
			payload.body
		);
	}

	finalizeVerification(payload) {
		return this._http.post(
			`${this.baseUrl}/admin/contractors/${payload.contractorId}/bpp_verification/finalize/`,
			payload.body
		);
	}

	uploadContractors(payload) {
		return this._http.post(`${this.baseUrl}/admin/contractors/`, payload.body);
	}

	editTemporaryId(payload) {
		return this._http.put(`${this.baseUrl}/admin/contractors/${payload.organizationId}/`, payload.body);
	}

	exportContractorDetails(payload) {
		return this._http.get(`${this.baseUrl}/admin/contractors/${payload.contractorId}/bpp_verification/`, {
			params: payload.params
		});
	}

	createOrganization(payload) {
		return this._http.post(this.baseUrl + '/admin/organizations/', payload.body);
	}

	getOrganizationTypeRoles(payload) {
		return this._http.get(`${this.baseUrl}/admin/organization_types/payload.organizationTypeId/roles/`, {
			params: payload.params
		});
	}

	getOrganization(payload) {
		return this._http.get(`${this.baseUrl}/organizations/`, { params: payload.params });
	}

	// organization
	getOrganizations(payload) {
		return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}contractors/`, {
			params: payload.params
		});
	}

	getOrganizationsAdmin(payload) {
		return this._http.get(`${this.baseUrl}/organizations/`, { params: payload.params });
	}
	changeApiAccessStatus(payload) {
		return this._http.put(
			`${this.baseUrl}/admin/organizations/${payload.organizationId}/api/?status=${payload.status}`,
			payload.body
		);
	}

	changeContractorStatus(payload) {
		return this._http.put(
			`${this.baseUrl}/admin/contractors/${payload.contractorId}/bar/?status=${payload.status}`,
			payload.body
		);
	}

	addOrganizations(payload) {
		return this._http.post(`${this.baseUrl}/admin/organizations/`, payload.body);
	}

	updateOrganizations(payload) {
		return this._http.put(`${this.baseUrl}/admin/organizations/${payload.organizationId}/`, payload.body);
	}

	deleteOrganizations(payload) {
		return this._http.delete(`${this.baseUrl}/admin/organizations/${payload.organizationId}/`);
	}

	// organization Types
	getOrganizationTypes(payload) {
		return this._http.get(`${this.baseUrl}/organizations/types/`, { params: payload.params });
	}

	addOrganizationTypes(payload) {
		return this._http.post(`${this.baseUrl}/admin/organization_types/`, payload.body);
	}

	updateOrganizationTypes(payload) {
		return this._http.put(`${this.baseUrl}/admin/organization_types/${payload.organizationTypeId}/`, payload.body);
	}

	deleteOrganizationTypes(payload) {
		return this._http.delete(`${this.baseUrl}/admin/organization_types/${payload.organizationTypeId}/`);
	}

	exportCompaniesReport(payload) {
		return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/contractors/export/`, {
			params: payload.params
		});
	}

	getDepartments() {
		return this._http.get(`${environment.API_SERVER}
        /organizations/?sord=desc&rows=100000000&page=1&_search=true&sidx=dateCreated&filters=
        %7B%22groupOp%22:%22AND%22,%22rules%22:%5B%7B%22field%22:%22organizationTypeId.organizationTypeId%22,
        %22op%22:%22eq%22,%22data%22:%22collaborator%22%7D,%7B%22field%22:%22organizationShortName%22,%22op%22:
        %22cn%22,%22data%22:%22%22%7D%5D%7D`);
	}

	getRoleFoDepartment() {
		return this._http.get(
			`${environment.API_SERVER}/admin/organization_types/collaborator/roles/?sord=desc&rows=100000000&page=1&_search=true&sidx=dateCreated`
		);
	}

	inviteExternalAppUsers(payload) {
		// tslint:disable-next-line: max-line-length
		return this._http.post(
			this.baseUrl +
				`/admin/invite_external_app_users/?roleId=${payload.rileId}&organizationId=${payload.organizationId}`,
			payload.body
		);
	}

	addDepartment(payload) {
		return this._http.post(`${this.baseUrl}/admin/organizations/`, payload);
	}
}
