import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
	name: "filter",
})
export class FilterPipe implements PipeTransform {
	transform(value: unknown[], filterString: string, propName: string): unknown[] {
		const resultArray = [];

		if (value.length === 0 || filterString === "" || propName === "") {
			return value;
		}

		for (const item of value) {
			if (item[propName] === filterString) {
				resultArray.push(item);
			}
		}
		return resultArray;
	}
}
