	 <h3 class="ui primary header" >{{ modal.context.title }}
	<i class="link times icon" (click)="modal.deny(undefined)"></i>
	</h3>
 <div class="content" >
       <div class="scroll-wrapper ui basic segment" [ngClass]="{'loading':pending}">
      <div *ngIf="success || error" tamsyMargin marginY="10px">
            <sui-message class="success" *ngIf="success">
                      <div class="header">
                        {{success}}
                    </div>
                </sui-message>
                <sui-message class="warning" *ngIf="error">
                      <div class="header">
                    {{error | json}}
                    </div>
                  </sui-message>
            </div>
         <!--Laboratory form  -->
         <div class="ui form" [formGroup]="replyForm">
            <div class="field" [ngClass]="displayFieldCss('documentTypeId')">
                                    <label>Select Document Type</label>
                                          <sui-select class="selection"
                                          formControlName="documentTypeId"
                                          [options]="documentsType"
                                          valueField="documentTypeId"
                                                 labelField="documentTypeName"
                                          placeholder="Select Document"
                                          #select>
                                    <sui-select-option *ngFor="let option of select.filteredOptions"
                                                      [value]="option">
                                    </sui-select-option>
                              </sui-select>
                              <!-- <select class="ui fluid dropdown" formControlName="procurement_type" placeholder="Select Document">
                              <option *ngFor="let unit of procurementType" [value]="unit">{{unit}}</option>
                              </select> -->
                              <app-field-error-display [displayError]="isFieldValid('documentTypeId')" errorMsg="Please select document type">
                        </app-field-error-display>
                  </div>

            <div class="field" [ngClass]="displayFieldCss('documentName')">
                  <label>Document Name</label>
            <input  formControlName="documentName" type="text" placeholder="Enter document name">
                  <app-field-error-display [displayError]="isFieldValid('documentName')" errorMsg="Please enter document name">
                  </app-field-error-display>
            </div>

            <div class="field" [ngClass]="displayFieldCss('document')">
                  <label>Select Document</label>
                    <input  
                        formControlName="document" 
                        type="file" 
                        accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xlx,.xlsx" 
                        (change)="onFileChange($event)"
                        placeholder="Click to select file">
                        <app-field-error-display [displayError]="isFieldValid('document')" errorMsg="Please select file">
                  </app-field-error-display>
              </div>



    </div>

 </div>
 </div>

      <div  class="actions">
    <button class="ui  basic primary button" (click)="modal.deny(undefined)">Cancel</button>
       <button class="ui  primary button" [ngClass]="{'yellow':pending}" [disabled]="pending" (click)="save()" autofocus>Done</button>

      </div>


