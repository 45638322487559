import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
	baseUrl: string = environment.API_SERVER;
	constructor(private _http: HttpClient) {}

	resetEmail(payload) {
		return this._http.put(
			`${this.baseUrl}/admin/app_users/${payload.appUserId}/reset/?new_user_email=${payload.newEmail}`,
			payload.body
		);
	}

	getOption(payload) {
		return this._http.get(this.baseUrl + '/admin/optionses/', { params: payload.params });
	}
	getUserApps(payload) {
		return this._http.get(this.baseUrl + `/users/${payload.appUserId}/assigned-applications/`, {
			params: payload.params
		});
	}

	addOption(payload) {
		return this._http.post(this.baseUrl + '/admin/optionses/', payload.body);
	}

	updateOption(payload) {
		return this._http.put(`${this.baseUrl}/admin/optionses/${payload.optionTypeId}/`, payload.body);
	}

	deleteOption(payload) {
		return this._http.delete(`${this.baseUrl}/admin/optionses/${payload.optionTypeId}/`);
	}
}
