import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

// import { UserService } from '../services/';

@Directive({ selector: '[isPermitted]' })
export class PermissionDirective implements OnInit {
	constructor(
		private templateRef: TemplateRef<any>,
		// private userService: UserService,
		private viewContainer: ViewContainerRef
	) {
		if (window.localStorage.getItem('permissions')) {
			let authTokenPermission = JSON.parse(localStorage.getItem('permissions'));
			this.permissions = authTokenPermission;
		}
	}

	id: string;
	action: string;

	@Input()
	set isPermitted(permission: any) {
		this.id = permission.id;
		this.action = permission.action;
	}
	permissions: Array<any> = [];
	ngOnInit() {
		let perm = this.permissions.find((res) => res.permissionId === this.id);
		if (perm && perm[this.action] === 'true') {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
