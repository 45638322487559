import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ViewEncapsulation,
	AfterViewInit,
	OnDestroy,
	Inject,
	Output,
	EventEmitter
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { DOCUMENT } from '@angular/common';
import { SuiModal, ComponentModalConfig, ModalSize } from '@richardlt/ng2-semantic-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import {CommentsService} from "../../services/comments";
// import {MessageService} from "../../services/message";
import { AppFilter } from '../../models/filter';
// import { CommentModal } from '../../modals/comment-modal/comment-modal.component';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil, tap, map } from 'rxjs/operators';
import { USER_ADMIN } from 'src/modules/mock';
interface Comment {
	messageId?: string;
	messageBody?: string;
	parentMessageId?: string;
	createdByOrganizationId?: string;
	allowContractorToRespond?: boolean;
	contractorResponded?: boolean;
	documentMetadata: any;
	dateCreated?: string;
	createdBy?: string;
	complaintId?: string;
	resolvedPath?: string;
	level?: number;
	username?: string;
	firstName?: string;
	lastName?: string;
	organizationShortName?: string;
	organizationLongName?: string;
	logoPath?: string;
	organizationTypeId?: string;
	fileName: string;
	filePath: string;
	fileDescription: string;
	extension: string;
	extensionGroup: string;
	appUserImage?: string;
	currentComplaintApprovalMonitoring?: any;
}

@Component({
	selector: 'app-comment-message',
	templateUrl: './comment.html',
	styleUrls: [ './comment.scss' ]
})
export class CommentComponent implements OnDestroy {
	@Input() equipment: any;
	@Input() parentId: string;
	@Input() complaintId: string;
	@Input() objectId: string;
	@Input() msg: Comment;
	@Input() objectType: string;
	@Input() isReply: boolean;
	@Output() reply: EventEmitter<any> = new EventEmitter();
	@Output() onApproval: EventEmitter<any> = new EventEmitter();
	successMessage;
	messageIndex;
	collapse: boolean = true;
	replyBlock;
	success: boolean;
	error: any;
	organization: any;
	user: any;
	organizationId: string;
	messages: Array<any>;
	pending: boolean;
	total;
	page;
	records;

	replyForm: FormGroup;
	messageParams: AppFilter;
	searchMessagesFilter = {
		groupOp: 'AND',
		rules: [
			{
				field: 'objectId',
				op: 'eq',
				data: ''
			}
		]
	};

	baseUrl;
	appUserImage: any;
	private unsubscribe$: Subject<void> = new Subject<void>();
	constructor(
		// private msgSvc: MessageService,
		// private commentSvc: CommentsService,
		private fb: FormBuilder
	) {
		this.messageParams = new AppFilter({ sidx: 'dateCreated', rows: 15, sord: 'desc' });
		this.baseUrl = environment.API_SERVER;

		this.replyForm = fb.group({
			comment: [ '', Validators.required ],
			equipmentName: [ '' ],
			to: [ '' ],
			documentId: [ '' ]
		});

		this.user = JSON.parse(window.localStorage.getItem('user'));
		if (this.user && this.user.image) {
			this.user.image = `${this.baseUrl}/public/image/?media=${this.user.image}`;
		} else {
			this.user.image = 'assets/avatar/1.jpg';
		}
	}
	onCollapse() {
		this.collapse = !this.collapse;
	}
	onReply(event) {}
	showReplyForm(form) {
		// this.replyBlock = form;
		this.reply.emit(form);
	}
	viewDoc(msg) {
		let url = `${this.baseUrl}/public/image/?media=${msg.documentId}`;
		// var filename = `${this._activity.name.split(" ").join("_")}.xlsx`;
		// 	var element = document.createElement("a");
		// 	let url = `${this.baseUrl}/activity-structure-versions/${this.currentStructure.activityStructureVersionId}/template/?to_filename=${this.currentStructure.name}_${filename}`;
		// 	window.open(url, "_blank");
	}
	uploadDoc() {}
	getMessages(page) {}

	send(parentComplaintMessage) {}
	approve(complaintId, status) {
		this.onApproval.emit({
			complaintApprovalId: complaintId,
			status: status
		});
	}

	ngOnDestroy() {}
}
