import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
@Component({
	selector: 'app-layout',
	templateUrl: './admin-layout.component.html',
	styleUrls: [ './admin-layout.component.scss' ]
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
	private unsubscribe$: Subject<void> = new Subject<void>();
	isBackground: boolean;
	constructor(private router: Router, private location: Location) {
		this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: NavigationEvent) => {
			if (event instanceof NavigationEnd) {
				console.log(event);
				let url = this.location.path();
				if (url.split('/')[2] == 'dashboard' || url.split('/')[2] == 'application') {
					this.isBackground = true;
				} else {
					this.isBackground = false;
				}
			}
		});
	}

	ngOnInit(): void {}
	ngOnDestroy(): void {
		// unsubscribe all subsciptons
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
