import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ncc-redirect',
	template: `
	<div class="ui loading segment" style="height:100%" >


	</div>
    `,
	styles: []
})
export class RedirectComponent implements OnDestroy {
	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
	private unsubscribe$: Subject<void> = new Subject<void>();
	pending = true;
	error;
	token: string;
	r: string;
	constructor(private router: Router, private route: ActivatedRoute, private userService: AuthenticationService) {
		this.route.queryParams.subscribe((params) => {
			// const token = params['theDestParamterIsentYou'];
			this.token = params['token'];
			this.r = params['return_url'];
			if (this.token) {
				this.login();
			} else {
				this.internal();
			}
		});
	}

	internal() {
		const role = JSON.parse(window.localStorage.getItem('user')).organizationId.organizationTypeId
			.organizationTypeId;
		if (role) {
			if (window.localStorage.getItem('url')) {
				const url = `/${window.localStorage.getItem('url')}`;
				window.location.replace(url);
				window.localStorage.removeItem('url');
			} else if (role === 'contractor') {
				this.router.navigate([ '/contractor' ]);
			} else {
				this.router.navigate([ '/admin' ]);
			}
		} else {
			console.log('Internal redirect');
			this.router.navigate([ '' ]);
		}
	}

	login() {
		this.pending = true;
		this.userService.login({ token: this.token }).pipe(takeUntil(this.unsubscribe$)).subscribe(
			(res: any) => {
				console.log(res);
				// this.pending = false;
				// this.success = "Congratulations! Please click on the button below to proceed to the contractor
				// dashboard in order to complete your registration ";
				this.userService.setUserValue(res.appUser);
				window.localStorage.setItem('jwt', res.token);
				window.localStorage.setItem('user', JSON.stringify(res.appUser));
				window.localStorage.setItem('organizationId', res.appUser.organizationId.organizationId);
				window.localStorage.setItem('organization', JSON.stringify(res.appUser.organizationId));
				window.localStorage.setItem('permissions', JSON.stringify(res['permissions']));
				window.localStorage.setItem('permission', JSON.stringify(res['permissions']));
				const role = res.appUser.organizationId.organizationTypeId.organizationTypeId;

				setTimeout(() => {
					this.pending = false;
					if (window.localStorage.getItem('pendingRedirect')) {
						window.location.replace(window.localStorage.getItem('pendingRedirect'));
					} else {
						if (window.localStorage.getItem('url')) {
							const url = `/${window.localStorage.getItem('url')}`;
							window.location.replace(url);

							window.localStorage.removeItem('url');
						} else if (role === 'contractor') {
							console.log('in contractor redirect');
							this.router.navigate([ '/contractor/dashboard' ]);
						} else {
							this.router.navigate([ '/admin' ]);
						}
					}
				}, 2000);
			},
			(error) => {
				this.pending = false;
				console.log('in error');
				if (error.status === 400) {
					this.error = error.error['developerMessage'];
					this.router.navigate([ '/home-page/dashboard' ]);
				} else {
					this.router.navigate([ '/home-page/dashboard' ]);
					this.error = error['developerMessage'];
				}
			}
		);
	}
	// private _resize$: Observable<number>;
	// mobile$ = this.sb.mobile$;
	// tablet$ = this.sb.tablet$;
	// desktop$ = this.sb.desktop$;

	// ngOnInit(): void {
	//   this._resize$ = Observable.fromEvent(window, 'resize')
	//     .pipe(
	//       debounceTime(200),
	//       mapTo( window.innerWidth),
	//       distinctUntilChanged(),
	//       startWith(window.innerWidth),
	//       tap(width => this.sb.setWindowWidth(width))
	//     );
	//   this._resize$.subscribe();
	// }
}
