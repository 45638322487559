	  <h3 class="ui primary header" >{{ modal.context.title }}
	<i id="close" class="link times icon" (click)="modal.deny(undefined)"></i>
      </h3>
 <div class="content" >
        <div class="scroll-wrapper ui  vertical basic segment" [ngClass]="{'loading':pending}">

     <div id="detail" #detail></div>

       </div>
        </div>
      <div  class="actions">

            <!-- <button class="ui  primary button" (click)="search()" autofocus>Search</button> -->
                   <button class="ui   primary button" (click)="modal.deny(undefined)">Close</button>
      </div>

