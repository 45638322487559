import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'myDateConverter' })
export class MyDateConverterPipe implements PipeTransform {
	transform(value: number, args: string[]): any {
		if (value) {
			console.log(value);
			var date = new Date(value);
			return date;
		}
	}
}
