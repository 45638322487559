<h3 class="ui primary header">
  {{ modal.context.title }}
  <i class="link times icon" (click)="modal.deny(undefined)"></i>
</h3>
<div class="content">
  <div class="scroll-wrapper ui basic segment" [ngClass]="{ loading: pending }">
    <div *ngIf="success || error" appMargin marginY="10px">
      <sui-message class="success" *ngIf="success">
        <div class="header">
          {{ success }}
        </div>
      </sui-message>
      <sui-message class="warning" *ngIf="error">
        <div class="header">
          {{ error | json }}
        </div>
      </sui-message>
    </div>
    <!-- <p>
Two-factor authentication (2FA) is an extra layer of security used when logging into websites or apps.
                </p> -->
    <div *ngIf="!isActive">
      <div class="ui form">
        <div class="grouped fields">
          <label
            ><h3>
              Two factor authentication(2FA) is an extra layer of security used
              when logging into websites or apps.
            </h3></label
          >
          <div class="field" appMargin marginT="10">
            <div>
              <sui-radio-button name="example" value="app" [(ngModel)]="eRadio"
                ><strong>Setup Using an app</strong> <br />Use an application on
                your phone to get two-factor authentication codes when prompted
                . We recommend using cloud-based TOTP apps such as: Google
                Authenticator or Microsoft Authenticator which can be downloaded
                on the Google Play Store or the Apple App Store
              </sui-radio-button>
            </div>
          </div>
          <!-- <div class="field" appMargin marginT="5">
            <sui-radio-button name="example" value="sms" [(ngModel)]="eRadio"><strong>Setup Using SMS </strong>
              <br/>
            An SMS with a 2-Factor Authentication Code would be sent to your mobile number when prompted. SMS cannot be delivered in all
          countries. Check that your country is supported before you select this option.</sui-radio-button>
        </div> -->
        </div>
      </div>
    </div>
    <div *ngIf="isActive" class="ui form" [formGroup]="twoFactorForm">
      <p>
        Scan the image below with the two-factor authentication app on your
        phone.
      </p>
      <div class="ui vertical basic segment" appMargin marginY="0px">
        <iframe
          allowfullscreen
          [src]="barCode"
          width="210px"
          height="210px"
          style="border: none"
        ></iframe>
      </div>
      <p>
        After scanning the QR code image, the app will display a code that you
        can enter below.
      </p>
      <div class="field" [ngClass]="displayFieldCss('twoFactor_name')">
        <label>Enter the code from the application</label>
        <input type="text" formControlName="twoFactor_name" />
        <app-field-error-display
          [displayError]="isFieldValid('twoFactor_name')"
          errorMsg="Please enter twoFactor name"
        >
        </app-field-error-display>
      </div>
    </div>
  </div>
</div>

<div class="actions">
  <button class="ui basic primary button" (click)="modal.deny(undefined)">
    Cancel
  </button>
  <button
    class="ui primary button"
    [ngClass]="{ yellow: pending }"
    [disabled]="pending"
    (click)="submit()"
    autofocus
  >
    Continue
  </button>
</div>
