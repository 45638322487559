import { HostBinding, Directive, Input } from '@angular/core';

@Directive({
	selector: '[appTabContent]'
})
export class AppTabContent {
	@HostBinding('class.tab') public readonly hasClasses: boolean;

	@Input('appTabContent') public id: string;

	@HostBinding('class.active') public isActive: boolean;

	constructor() {
		this.isActive = false;

		this.hasClasses = true;
	}
}
