import {Injectable} from "@angular/core";
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

/**
 * author Tari
 * @dateCreated 10 Oct 2023
 * @description Transforms all http response. Replaces the response body with the content of response.body.data
 * @modifiedBy Tari
 */
@Injectable()
export class CustomResponseInterceptor implements HttpInterceptor {
	constructor() {

	}
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error) => {
				// console.log('Custom Error: ', error)
				const message = error?.errorMessage ?? error?.error?.errorMessage ?? error?.error?.data?.errorMessage
				const status = error?.error?.statusCode ?? error?.status
				return throwError({error: message, status: status})
			}),
			map((response: HttpEvent<any>) => {
				if (response instanceof HttpResponse && response.body && response.body.data) {
					return response.clone({body: response.body.data});
				}
				return response;
			})
		);
	}
}