import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class CoreService {
	// host: string = environment.host;
	// ct = new Metadata()
	baseUrl: string = environment.API_SERVER;

	constructor(private _http: HttpClient) {}
	getDownloadExport(payload) {
		return this._http.get(`${this.baseUrl}/public/media/?media=${payload.media}`, { params: payload.params });
	}
	//search country
	loadComplaintType(payload) {
		return this._http.get(this.baseUrl + '/admin/complaint_types/', { params: payload.params });
	}

	//load organiztion type
	loadOrganizationType(payload) {
		return this._http.get(this.baseUrl + '/admin/organization_types/', { params: payload.params });
	}

	//search country
	getCountries(payload) {
		const params = new HttpParams();
		params.set('sidx', 'countryName');
		params.set('sord', 'desc');
		params.set('rows', '3000');
		params.set('page', '1');
		params.set('_search', 'true');
		params.set('filters', '{}');
		return this._http.get(this.baseUrl + '/public/countries/', { params: params });
	}

	getStates(payload) {
		const params = new HttpParams();
		params.set('sidx', 'stateName');
		params.set('sord', 'desc');
		params.set('rows', '3000');
		params.set('page', '1');
		params.set('_search', 'true');
		params.set('filters', payload.filter);
		// { params: new HttpParams().set('name', term) }
		return this._http.get(`${this.baseUrl}/public/states/`, { params: params });
	}

	getPrbs(payload) {
		const params = new HttpParams()
			.set('sidx', 'optionId')
			.set('sord', 'desc')
			.set('rows', '3000')
			.set('page', '1')
			.set('_search', 'true')
			.set('filters', payload.filter);
		console.log(params);
		return this._http.get(this.baseUrl + '/public/prbs/', { params: params });
	}

	getLgas(payload) {
		const params = new HttpParams()
			.set('sidx', 'lgaName')
			.set('sord', 'desc')
			.set('rows', '3000')
			.set('page', '1')
			.set('_search', 'true')
			.set('filters', payload.filter);
		console.log(params);
		return this._http.get(this.baseUrl + '/public/lgas/', { params: params });
	}

	getOptions(payload) {
		console.log(payload);
		const params = new HttpParams()
			.set('sidx', 'optionId')
			.set('sord', 'desc')
			.set('rows', '3000')
			.set('page', '1')
			.set('_search', 'true')
			.set('filters', payload.filter);
		console.log(params);
		return this._http.get(this.baseUrl + '/public/well_parsed_options/', { params: params });
	}

	submitComplaint(payload) {
		return this._http.post(this.baseUrl + '/public/complaint/', payload.body);
	}

	addOption(payload) {
		return this._http.post(this.baseUrl + '/admin/optionses/', payload.body);
	}

	updateOption(payload) {
		return this._http.post(`${this.baseUrl}/admin/optionses/${payload.optionId}/`, payload.body);
	}
	deleteOption(payload) {
		return this._http.delete(`${this.baseUrl}/admin/optionses/${payload.optionId}/`);
	}

	getOptionType(payload) {
		const params = new HttpParams()
			.set('sidx', 'optionId')
			.set('sord', 'desc')
			.set('rows', '3000')
			.set('page', '1')
			.set('_search', 'true')
			.set('filters', payload.filter);
		console.log(params);
		return this._http.get(this.baseUrl + '/admin/option_types/', { params: params });
	}

	addOptionType(payload) {
		return this._http.post(this.baseUrl + '/admin/option_types/', payload.body);
	}

	updateOptionType(payload) {
		return this._http.post(`${this.baseUrl}/admin/option_types/${payload.optionTypeId}/`, payload.body);
	}
	deleteOptionType(payload) {
		return this._http.delete(`${this.baseUrl}/admin/option_types/${payload.optionTypeId}/`);
	}

	getErrorStructure(payload) {
		return this._http.get(`${this.baseUrl}/public/complaint/ahead_of_time_interpretation/${payload.code}/`);
	}
}
