import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RoleService {
	baseUrl: string = environment.API_SERVER;
	constructor(private _http: HttpClient) {}
	getRoles(payload) {
		return this._http.get(`${this.baseUrl}/admin/roles/`, {
			params: payload.params
		});
	}
	addRoles(payload) {
		return this._http.post(`${this.baseUrl}/admin/roles/`, payload.body);
	}
	deleteRole(payload) {
		return this._http.delete(`${this.baseUrl}/admin/roles/${payload.roleName}/`);
	}
	getRolePermission(payload) {
		return this._http.get(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/users/${payload.appUserId}/permissions/aggregate/roles/${payload.roleName}/permissions/`
		);
	}
	updateRoles(payload) {
		return this._http.put(`${this.baseUrl}/admin/roles/${payload.roleId}/`, payload.body);
	}

	assignRolePermission(payload) {
		return this._http.put(`${this.baseUrl}/admin/roles/${payload.roleName}/permissions/`, payload.body);
	}
	removeRoles(payload) {
		return this._http.delete(`${this.baseUrl}/admin/roles_aggregated/${payload.id}/`);
	}
}
