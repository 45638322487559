<div class="error-container">
	<div class="error-content">
		<div class="icon-container">
			<svg width="74" height="80" viewBox="0 0 74 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.979711" y="0.967992" width="45.2997" height="50.4279" rx="5.55562" fill="white"/>
        <rect x="0.979711" y="0.967992" width="45.2997" height="50.4279" rx="5.55562" stroke="#6E7478" stroke-width="1.70942"/>
        <rect x="10.8086" y="10.7971" width="25.6413" height="1.70942" rx="0.854711" fill="#6E7478"/>
        <rect x="10.8086" y="25.3273" width="25.6413" height="1.70942" rx="0.854711" fill="#6E7478"/>
        <rect x="10.8086" y="39.8573" width="25.6413" height="1.70942" rx="0.854711" fill="#6E7478"/>
        <rect x="9.52659" y="18.0627" width="45.2997" height="50.4279" rx="5.55562" fill="white"/>
        <rect x="9.52659" y="18.0627" width="45.2997" height="50.4279" rx="5.55562" stroke="#6E7478" stroke-width="1.70942"/>
        <rect x="19.3555" y="27.8919" width="25.6413" height="1.70942" rx="0.854711" fill="#6E7478"/>
        <rect x="19.3555" y="42.422" width="25.6413" height="1.70942" rx="0.854711" fill="#6E7478"/>
        <rect x="19.3555" y="56.952" width="25.6413" height="1.70942" rx="0.854711" fill="#6E7478"/>
        <circle cx="47.8231" cy="45.6756" r="19.1618" fill="white" stroke="#6E7478" stroke-width="1.51777"/>
        <circle cx="47.8228" cy="45.6754" r="13.4702" fill="white" stroke="#6E7478" stroke-width="1.51777"/>
        <rect x="42.7969" y="44.5469" width="7.32217" height="1.51777" rx="0.758885" transform="rotate(-48.8369 42.7969 44.5469)" fill="#6E7478"/>
        <rect x="46.875" y="51.9452" width="7.32217" height="1.51777" rx="0.758885" transform="rotate(-48.8369 46.875 51.9452)" fill="#6E7478"/>
        <rect x="58.2578" y="61.0498" width="1.89721" height="11.3833" transform="rotate(-37.1008 58.2578 61.0498)" fill="#6E7478"/>
        <rect x="64.2347" y="70.1869" width="3.36427" height="10.4347" transform="rotate(-37.1008 64.2347 70.1869)" fill="white" stroke="#6E7478" stroke-width="0.948606"/>
      </svg>
		</div>
		<div class="text-container">
			<span class="error-404-text">404</span>
			<div class="error-text-container">
				<span>Not found</span>
				<span>The page you’re looking for is not found</span>
			</div>
		</div>
		<div class="button-container" *ngIf="buttonState">
			<esl-button
				class="button"
				buttonType="primary"
				buttonSize="medium"
				buttonState="basic"
				[label]="'Go Back'"
				[isClickable]="true"
				(clickAction)="goBack()"
			></esl-button>
		</div>
	</div>
</div>
