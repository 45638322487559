import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, Router, Route, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanLoad {
	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.isAuthenticated();
	}

	canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
		return this.isAuthenticated();
	}

	private isAuthenticated() {
		if (this.authenticationService.getAuthorizationHeader()) {
			console.log('auth');
			return true;
		} else {
			console.log('no auth');
			this.router.navigate([ '/home-page' ]);
			return false;
		}
	}
}
