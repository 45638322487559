import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, Router, Route, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {
	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// return this.isAuthenticated();
		const user = this.authenticationService.userValue;
		if (user && user.organizationId.organizationTypeId.organizationTypeId != 'contractor') {
			return true;
		} else {
			// not logged in so redirect to login page with the return url
			// this.router.navigate(["/landing-page"], {queryParams: {returnUrl: state.url}});
			this.router.navigate([ '/home-page' ]);
			alert("You're not authorised to view this page");
			return false;
		}
	}
}
