import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-applicant-layout',
	templateUrl: './applicant-layout.component.html',
	styleUrls: [ './applicant-layout.component.scss' ]
})
export class ApplicantLayoutComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
