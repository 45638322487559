import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'esl-error-component',
  templateUrl: './error-component.component.html',
  styleUrls: ['./error-component.component.scss']
})
export class ErrorComponentComponent implements OnInit {

  @Input() buttonState: boolean = true;
  @Input() isSmallScreen: boolean = false;
  @Input() screenHeight: string = '';
  @Input() screenWidth: string = '';
  @Input() smallScreenPadding: string = '60px';
  @Input() largeScreenPadding: string = '80px';

  constructor(
    private location: Location,
  ) { }

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
  }

}
