// Contirbute at this
// Project https://github.com/hasanhameed07/angular2-validators
import { Injector, ReflectiveInjector } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

interface ValidationResult {
	[key: string]: boolean;
}

export class SharedValidators {
	url = environment.API_SERVER;

	static email(control: FormControl): ValidationResult {
		// tslint:disable-next-line:max-line-length
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (control.value !== '' && re.test(control.value)) {
			return null;
		}

		return { email: true };
	}

	static hash(control: FormControl): ValidationResult {
		const re = /^[a-zA-Z\d-_]+$/;
		if (control.value !== '' && re.test(control.value)) {
			return null;
		}

		return { hash: true };
	}

	static columnName(control: FormControl): ValidationResult {
		const re = /^[a-zA-Z\d_]+$/;
		if (control.value !== '' && re.test(control.value)) {
			return null;
		}
		return { columnName: true };
	}

	static strongPassword(control: FormControl): ValidationResult {
		const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*()_+-])(?=.{8,})/;
		if (control.value !== '' && re.test(control.value)) {
			return null;
		}

		return { strongPassword: true };
	}

	static matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
		return (group: FormGroup): { [key: string]: any } => {
			const password = group.controls[passwordKey];
			const confirmPassword = group.controls[confirmPasswordKey];
			if (!password || !confirmPassword) {
				return null;
			}
			return password.value === confirmPassword.value ? null : { matchingPasswords: true };
		};
	}

  static differentPasswords(oldPasswordKey: string, newPasswordKey: string){

    return (group: FormGroup): {[key: string]: any} => {
      const oldPassword = group.controls[oldPasswordKey];
      const newPassword = group.controls[newPasswordKey];
      if (!oldPassword || !newPassword) {
        return null;
      }
      return oldPassword.value !== newPassword.value ? null : { differentPasswords: true };
    }
  }
}
