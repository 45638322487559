import { Injectable } from '@angular/core';
import { Subject, Observer, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CommonServices {
	// host: string = environment.host;
	// ct = new Metadata()
	baseUrl: string = environment.API_SERVER;

	constructor(private _http: HttpClient) {}
	//search country
	getCountries(payload) {
		return this._http.get(this.baseUrl + '/public/countris/');
	}

	getStates(payload) {
		return this._http.get(`${this.baseUrl}/public/state/`);
	}

	messageTemplates(payload) {
		return this._http.get(`${this.baseUrl}/public/message_templates/${payload.messageTemplateId}/`);
	}

	getLgas(payload) {
		return this._http.get(this.baseUrl + '/public/options/');
	}

	getOption(payload) {
		return this._http.get(this.baseUrl + '/public/options/', { params: payload.params });
	}

	getTemplate(payload) {
		return this._http.get(this.baseUrl + '/public/message_templates/' + `${payload.messageTemplateId}/`);
	}
	initiateTwoFactor(payload) {
		return this._http.post(this.baseUrl + `/users/${payload.appUserId}/two_factor_auth/`, payload.body);
	}
	validateTwoFactor(payload) {
		return this._http.get(this.baseUrl + `/users/${payload.appUserId}/two_factor_auth/`, {
			params: payload.params
		});
	}
	enableTwoFactor(payload) {
		return this._http.put(
			this.baseUrl + `/users/${payload.appUserId}/two_factor_auth/?otp=${payload.otp}`,
			payload.body
		);
	}
	disableTwoFactor(payload) {
		return this._http.delete(this.baseUrl + `/users/${payload.appUserId}/two_factor_auth/`);
	}
}
