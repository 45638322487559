
	<div class="ui basic  segment" >
        <sui-dimmer class="inverted" [(isDimmed)]="pending" isClickable="false">
        <div class="ui text loader">Loading </div>
    </sui-dimmer>
 <div  appMargin marginL="5">
							<div *ngIf="success || error" appMargin marginY="10px">
							<sui-message class="success" *ngIf="success">
									<div class="header">
										{{success}}
									</div>
								</sui-message>
								<sui-message class="warning" *ngIf="error">
									<div class="header">
									{{error | json}}
									</div>
								</sui-message>
							</div>

							  <div
							id="scrollMe"
							infinite-scroll
							[scrollWindow]="false"
							[infiniteScrollUpDistance]="2"
							(scrolled)="onScrollDown($event)"
							(scrolledUp)="onScrollUp($event)"
									appPadding="0"  class="ui basic segment" [ngClass]="{'loading':pending}">
											<div  *ngFor="let comment of messages">
                                                                                                <app-comment-message
                                                                                                	[parentId]="parentMessageId"
                                                                                             	[complaintId]="complaintId"
                                                                                                [msg]="comment"
                                                                                                (reply)="reply.emit($event)"
                                                                                                 (onApproval)="approve($event)"
                                                                                                >
												</app-comment-message>
											</div>
								</div>
						</div>

	</div>

