import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent, ApplicantLayoutComponent, ErrorPageComponent, RedirectComponent } from './components';
import { AppCustomPreloader } from './strategy';
import { AuthenticationGuard } from './guards/authentication';
import { AdminAuthGuard } from './guards/admin';
import { ContractorAuthGuard } from './guards/contractor';
const routes: Routes = [
	{
		path: 'contractor',
		// canLoad: [ AuthenticationGuard, ContractorAuthGuard ],
		data: { preload: true },
		loadChildren: () => import('../features/contractor/contractor.module').then((m) => m.ContractorModule)
		// data: {roles: [RoleType.Default_Contractor, RoleType.Contractor_Admin ]}
	},
	{
		path: '',
		redirectTo: 'home-page',
		pathMatch: 'full'
	},
	{
		path: 'redirect',
		component: RedirectComponent
	},
	{
		path: 'home-page',
		data: { preload: true },
		loadChildren: () => import('../features/public/public.module').then((m) => m.PublicModule)
		// canActivate: [AuthenticationGuard],
		// data: {roles: [RoleType.Default_Contractor, RoleType.Contractor_Admin ]}
	},
	{
		path: '',
		component: AdminLayoutComponent,
		canLoad: [ AuthenticationGuard, AdminAuthGuard ],
		children: [
			{
				path: '',
				redirectTo: 'admin',
				pathMatch: 'full'
			},
			{
				path: 'admin',
				// canLoad: [ AuthenticationGuard ],
				data: { preload: true },
				component: ApplicantLayoutComponent,
				loadChildren: () => import('../features/admin/admin.module').then((m) => m.AdminModule)
			},
			{
				path: 'notifications',
				// canLoad: [AuthenticationGuard],
				data: { preload: true },
				component: ApplicantLayoutComponent,
				loadChildren: () =>
					import('../features/notification/notification.module').then((m) => m.NotificationModule)
			}
		]
	},
	 // wild card route for not found error
	 {
		path: '**',
		pathMatch: 'full',
		component: ErrorPageComponent
	  },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			onSameUrlNavigation: 'reload',
			preloadingStrategy: AppCustomPreloader
		})
	],
	exports: [ RouterModule ],
	providers: [ AppCustomPreloader ]
})
export class CoreRoutingModule {}
