import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esl-button',
  templateUrl: './esl-button.component.html',
  styleUrls: ['./esl-button.component.scss']
})
export class EslButtonComponent {
  @Input() buttonState: buttonStates = "normal";
  @Input() buttonActionState: buttonActionStates = "default";
  @Input() buttonType: buttonType = "primary";
  @Input() primaryButtonBackgroundColor: string = '#3f4395';
  @Input() primaryButtonTextColor: string = '#ffffff';
  @Input() basicButtonBackgroundColor: string = 'transparent';
  @Input() basicButtonTextColor: string = '#3f4395';
  @Input() hasPrefixIcon: boolean = false;
  @Input() hasSuffixIcon: boolean = false;
  @Input() hasLine: boolean = false;
  @Input() hasDropdown: boolean = false;
  @Input() buttonSize: fontSizeType = "medium";
  @Input() isClickable: boolean = false;
  @Input() isSmallScreen: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() label?: string = "Actions";
  @Input() buttonMenu: any = ['Option 1', 'Option 2'];
  @Output() buttonAction: EventEmitter<any> = new EventEmitter();
  @Output() clickAction: EventEmitter<any> = new EventEmitter();


  getOutput(e: any) {
    console.log(e, 'event');
  }
}

type fontSizeType = "small" | "medium" | "large";
type buttonStates = "basic" | "outline" | "subdue" | "normal";
type buttonActionStates = "default" | "hovered" | "pressed" | "depressed";

type buttonType = "primary" | "destructive" | "plain" | "plain-destructive";

