import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class ChatService {
	baseUrl: string = environment.API_SERVER;
	private refreshMessages: Subject<any> = new Subject<any>();
	private refreshApproval: Subject<any> = new Subject<any>();
	private refreshDetail: Subject<any> = new Subject<any>();
	constructor(private _http: HttpClient) {}
	setRefreshDetail(chart): void {
		this.refreshDetail.next(chart);
	}
	getRefreshDetail(): Observable<any> {
		return this.refreshDetail.asObservable();
	}
	setRefreshMessages(chart): void {
		this.refreshMessages.next(chart);
	}
	getRefreshMessages(): Observable<any> {
		return this.refreshMessages.asObservable();
	}
	setRefreshApproval(chart): void {
		this.refreshApproval.next(chart);
	}
	getRefreshApproval(): Observable<any> {
		return this.refreshApproval.asObservable();
	}
	contractorAppAccess(payload) {
		return this._http.put(
			`${this.baseUrl}/admin/contractors/${payload.organizationId}/status/${payload.status}/`,
			payload.body
		);
	}
	getMessage(payload) {
		return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/comments/`, {
			params: payload.params
		});
	}
	getComplaintType(payload) {
		const params = new HttpParams()
			.set('sidx', 'optionId')
			.set('sord', 'desc')
			.set('rows', '3000')
			.set('page', '1')
			.set('_search', 'true');
		return this._http.get(this.baseUrl + '/public/complaint_types/');
	}
	getOptions(payload) {
		const params = new HttpParams()
			.set('sidx', 'optionId')
			.set('sord', 'desc')
			.set('rows', '3000')
			.set('page', '1')
			.set('_search', 'true')
			.set('filters', payload.filter);
		return this._http.get(this.baseUrl + '/public/options/', { params: params });
	}
	// /organizations/{organizationId}/complaints/{complaintId}/update_db/
	// Get all  compliant
	resolveUpdateDb(payload) {
		return this._http.put(
			`${this.baseUrl}/organizations/${payload.organizationId}/complaints/${payload.complaintId}/update_db/`,
			payload.body
		);
	}

	markMessageRead(payload) {
		return this._http.put(
			`${this.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/read/`,
			payload.body
		);
	}

	// Get all  compliant
	createMessage(payload) {
		return this._http.post(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/messeges/${payload.complaintId}/complaint_message/`,
			payload.body
		);
	}
	// Get all  compliant
	getAllCollaborator(payload) {
		return this._http.get(`${this.baseUrl}/admin/collaborators/`, { params: payload.params });
	}

	getAllOrg(payload) {
		console.log(payload);
		return this._http.get(`${this.baseUrl}/organization_types/${payload.type}/organizations/`, {
			params: payload.params
		});
	}

	// Get all my complaints approval in an organization
	getComplaintApproval(payload) {
		return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/complaint_approvals/`, {
			params: payload.params
		});
	}

	//sGet my complaint monitoring
	getMyComplaintMonitoring(payload) {
		return this._http.get(
			`${this.baseUrl}/organizations/${payload.organizationId}/complaint_approval_monitoring/`,
			{ params: payload.params }
		);
	}

	// Get all compliants monitoring for a single complaint approval
	getComplaintApprovalMonitoring(payload) {
		return this._http.get(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/complaint_approvals/${payload.complaintApprovalId}/complaint_approval_monitoring/`,
			{ params: payload.params }
		);
	}

	getComplaintApprovalMessages(payload) {
		return this._http.get(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/complaint_approval_monitoring/`,
			{ params: payload.params }
		);
	}

	// Get all watching compliant
	getAllWatchingComplaint(payload) {
		return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/complaint_watching/`, {
			params: payload.params
		});
	}

	// Get all watching compliant
	getAllComplaintWatchers(payload) {
		return this._http.get(
			`${this.baseUrl}/organizations/${payload.organizationId}/messages/${payload.companyId}/watchers/`,
			{ params: payload.params }
		);
	}

	// Get all  compliant
	getAllComplaint(payload) {
		if (window.localStorage.getItem('isAdmin')) {
			return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/contractors/`, {
				params: payload.params
			});
		} else {
			return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/contractors/`, {
				params: payload.params
			});
		}
	}

	// Get single  compliant
	getComplaint(payload) {
		if (window.localStorage.getItem('isAdmin')) {
			return this._http.get(`${this.baseUrl}/admin/complaints/${payload.complaintId}/`, {
				params: payload.params
			});
		} else {
			return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/contractors/`, {
				params: payload.params
			});
		}
	}

	// Acknowledge complaint
	acknowledgeComplaint(payload) {
		return this._http.put(
			`${this.baseUrl}/organizations/${payload.organizationId}/complaints/${payload.complaintId}/`,
			payload.body
		);
	}

	// Acknowledge complaint
	exportComplaint(payload) {
		return this._http.get(`${this.baseUrl}/organizations/${payload.organizationId}/contractors/export/`, {
			params: payload.params
		});
	}

	// Approve complaint and monitoring
	approvalComplaintMonitoring(payload) {
		return this._http.put(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/messages_approvals/${payload.complaintApprovalId}/?status=${payload.status}`,
			payload.body
		);
	}

	// Get all aproval for a single  compliant
	getAllApprovalForComplaint(payload) {
		return this._http.get(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/complaint/${payload.complaintId}/complaint_approvals/`,
			{ params: payload.params }
		);
	}

	//create complaint message
	createComplaint(payload) {
		return this._http.post(`${this.baseUrl}/organizations/${payload.organizationId}/comments/`, payload.body);
	}

	// Get all complaints messages for a complaint
	getAllComplaintMessages(payload) {
		if (window.localStorage.getItem('isAdmin')) {
			return this._http.get(`${this.baseUrl}/admin/complaints/${payload.complaintId}/complaint_messages/`, {
				params: payload.params
			});
		} else {
			return this._http.get(
				`${this
					.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/complaint_messages/`,
				{ params: payload.params }
			);
		}
	}

	// Get all complaints messages for a complaint
	getAllComplaintMessagesView(payload) {
		if (window.localStorage.getItem('isAdmin')) {
			return this._http.get(`${this.baseUrl}/admin/complaints/${payload.complaintId}/complaint_messages_view/`, {
				params: payload.params
			});
		} else {
			return this._http.get(
				`${this
					.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/complaint_messages_view/`,
				{ params: payload.params }
			);
		}
	}

	//escalate complaint
	escalateComplaint(payload) {
		return this._http.put(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/escalate/?toCollaboratorId=${payload.collaboratorId}`,
			payload.body
		);
	}

	//extend complaint dayz
	extendComplaint(payload) {
		return this._http.post(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/extension/${payload.days}/`,
			payload.body
		);
	}
	//resolve complaint
	resolveComplaint(payload) {
		return this._http.put(
			`${this
				.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/resolve/?updateDb=${payload.updateDb}`,
			payload.body
		);
	}
	//terminate complaint
	terminateComplaint(payload) {
		return this._http.put(
			`${this.baseUrl}/organizations/${payload.organizationId}/messages/${payload.complaintId}/terminate/`,
			payload.body
		);
	}
	//terminate complaint
	unwatchComplaint(payload) {
		return this._http.delete(
			`${this.baseUrl}/organizations/${payload.organizationId}/messages/${payload.companyId}/watchers/`,
			payload.body
		);
	}
	//is user watching
	watchingComplaint(payload) {
		return this._http.post(
			`${this.baseUrl}/organizations/${payload.organizationId}/messages/${payload.companyId}/watchers/`,
			payload.body
		);
	}

	//is user watching
	unwatchingComplaint(payload) {
		return this._http.delete(
			`${this.baseUrl}/organizations/${payload.organizationId}/messages/${payload.companyId}/watchers/`,
			payload.body
		);
	}

	getComplaintsCount(payload) {
		return this._http.get(`${this.baseUrl}/admin/complaints_count/?organizationId=${payload.organizationId}`);
	}
}
