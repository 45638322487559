import { Pipe, PipeTransform, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { MyDateConverterPipe } from './date';
declare var $: any;

@Pipe({ name: 'rowValue' })
export class DataRowValuePipe implements PipeTransform {
	baseurl: string = environment.API_SERVER;
	constructor(private sanitizer: DomSanitizer) {}
	transform(value, args: any): any {
		// console.log(value);
		const rowData = value;
		const columns = args.column;
		const unFormatedColumns = args.unformat;
		const results = [];
		// console.log(columns);
		//look for all columns with type photo
		const photo_types_columns = columns.filter((x) => x.type === 'photo' || x.type === 'file');
		const poho = [];
		// const file_types_columns = columns.filter((x) => x.type === 'file');
		// const file = [];
		if (photo_types_columns.length > 1) {
			for (let i = 0; i < photo_types_columns.length; i++) {
				poho.push(photo_types_columns[i]['name']);
			}
		} else if (photo_types_columns.length === 1) {
			poho.push(photo_types_columns[0]['name']);
		}

		// if (file_types_columns.length > 1) {
		// 	for (let i = 0; i < file_types_columns.length; i++) {
		// 		file.push(file_types_columns[i]['name']);
		// 	}
		// } else if (file_types_columns.length === 1) {
		// 	file.push(file_types_columns[0]['name']);
		// }

		// console.log(rowData);
		var dataWeDontWant: Array<any> = [ 'activityDataId', '_id_' ];
		for (let i = 0; i < columns.length; i++) {
			const realData = columns[i]['name'];

			if (rowData.hasOwnProperty(realData) && dataWeDontWant.indexOf(realData) == -1) {
				// console.log(realData);
				if (poho.indexOf(realData) > -1) {
					const self = this;
					let photoArray = [];
					$.each(rowData[realData].split(' '), function(index, photo) {
						if (photo !== '') {
							photoArray.push(`
							<a class='fancybox' rel='${rowData.activityDataId}' href='${self.baseurl}/public/image/?media=${photo}'>
								  <img class='imgThumbnail' alt='${photo
										.split('/')
										.pop()}' src='${self.baseurl}/public/image/?media=${photo}' onerror="this.src='./assets/icons/documents.svg'">
							</a>`);
						}
					});
					results.push(photoArray);
					$('.fancybox').fancybox();
				} else {
					let head = columns[i]['label'];
					if (
						rowData[realData] !== null &&
						'object' === typeof rowData[realData] &&
						columns[i]['type'] !== 'select all that apply'
					) {
						// console.log(rowData[realData]);

						let items = rowData[realData];
						if (!Array.isArray(rowData[realData])) {
							items = [ rowData[realData] ];
						}
						const lnum = Object.keys(items).length;
						// console.log(rowData[realData]);
						// console.log(items)
						let content = `
                        <div id='faq-list-3' class='panel-group accordion-style1 accordion-style2'>
                        <div class='panel panel-default'>
                            <div class='panel-heading'>
                            <a data-parent='#faq-list-3' data-toggle='collapse' class='accordion-toggle collapsed'>
                                <i class='ace-icon fa fa-plus smaller-80' data-icon-hide='ace-icon fa fa-minus' data-icon-show='ace-icon fa fa-plus'></i>&nbsp;
                                ${lnum} records
                            </a>
                            </div>

                            <div class='panel-collapse collapse' id='faq-3-2'>
                            <div class='panel-body no-padding'>
                            `;

						// prepare children table headers
						content += '<table class="table table-striped table-bordered table-condensed">';
						let childColumns = findChildrenHeads(unFormatedColumns, head);
						content += `<tr>`;
						for (var xc in childColumns) {
							content += `<td>${childColumns[xc].label}</td>`;
						}
						content += `</tr>`;
						// loop through the children items data
						for (let g = 0; g < items.length; g++) {
							let eachItemObj = items[g];
							content += `<tr>`;
							for (var xx in childColumns) {
								let name = childColumns[xx]['name'];
								if (eachItemObj.hasOwnProperty(name)) {
									content += `<td>${eachItemObj[name]}</td>`;
								}
							}
							content += `</tr>`;
						}
						content += `</table>
                            </div>
                        </div>
                        </div>`;
						results.push(content);
					} else if (columns[i]['type'] == 'select all that apply') {
						const trail = '...';
						results.push(
							rowData[realData]
								? /[,]/.test(rowData[realData]) ? rowData[realData].join(',') : rowData[realData]
								: ''
						);
					} else {
						// console.log(columns[i]);
						// console.log(realData);
						// console.log(rowData);
						// console.log();
						const trail = '...';
						// &&
						if (rowData[realData] !== null) {
							results.push(
								rowData[realData].length > 60
									? rowData[realData].substring(0, 60) + trail
									: rowData[realData]
							);
						} else {
							results.push('null');
						}
					}
				}
			} else if (!rowData.hasOwnProperty(realData)) {
				results.push('');
			}
		}
		function findChildrenHeads(arr, rename) {
			let arrx = [];
			let arrayFlatten = function(arr) {
				for (let key in arr) {
					if (arr[key].type === 'group') {
						arrayFlatten(arr[key].children);
					} else if (arr[key].name === rename) {
						let items = arr[key]['children'];
						for (var mk in items) {
							arrx.push({ name: items[mk]['name'], label: items[mk]['label'] });
						}
					}
				}
			};
			arrayFlatten(arr);
			return arr;
		}
		console.log(results);
		return results;
	}
}
