import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare var EventSource;

@Injectable({ providedIn: 'root' })
export class Sse {
	constructor() {}

	observe(sseUrl: string): Observable<string> {
		return new Observable<string>((obs) => {
			const es = new EventSource(sseUrl);
			es.addEventListener('message', (evt) => {
				console.log(evt.data);
				let stream = JSON.parse(evt.data).progress;
				if (stream >= 100) {
					obs.next(evt.data);
					obs.closed;
					es.close();
				} else {
					obs.next(evt.data);
				}
			});
			return () => es.close();
		});
	}
}
