import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
	transform(str: string, strLength: number = 250, isTrail: boolean = true) {
		const withoutHtml = str.replace(/(<([^>]+)>)/gi, '');

		if (str.length >= strLength) {
			return isTrail ? `${withoutHtml.slice(0, strLength)}...` : `${withoutHtml.slice(0, strLength)}`;
		}

		return withoutHtml;
	}
}
