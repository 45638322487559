import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuditService {
	baseUrl: string = environment.API_SERVER;
	constructor(private _http: HttpClient) {}

	getAuditTral(payload) {
		return this._http.get(this.baseUrl + '/admin/audit_trail/', { params: payload.params });
	}
	exportAudit(payload) {
		return this._http.get(this.baseUrl + '/admin/audit_trail/export/', { params: payload.params });
	}

	getEndpointRequests(payload) {
		return this._http.get(`${this.baseUrl}/endpoint_requests/`, { params: payload.params });
	}
}
