import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private refreshNotificationCount: Subject<any> = new Subject<any>();
  baseUrl: string = environment.API_SERVER;
  constructor(private _http: HttpClient) {}
  setRefreshNotification(chart): void {
    this.refreshNotificationCount.next(chart);
  }
  getRefreshNotification(): Observable<any> {
    return this.refreshNotificationCount.asObservable();
  }

  getContractors(payload) {
    return this._http.get(this.baseUrl + '/public/barred_contractors/', {
      params: payload.params,
    });
  }

  getDataStructure(payload) {
    return this._http.post(
      this.baseUrl + '/users/complaint/just_in_time_interpretation/contact_us/',
      payload.body
    );
  }

  getNotifications(payload) {
    return this._http.get(
      `${this.baseUrl}/users/${payload.appUserId}/notifcations/`,
      { params: payload.params }
    );
  }

  markNotifications(payload) {
    return this._http.put(
      `${this.baseUrl}/users/${payload.appUserId}/notifcations/?read_status=${payload.read_status}`,
      { ids: payload.ids }
    );
  }

  resendEmailConfirmation(payload) {
    return this._http.put(
      `${this.baseUrl}/users/${payload.appUserId}/initate_email_verification/`,
      payload.body
    );
  }

  resendCompanyEmailConfirmation(payload) {
    return this._http.put(
      `${this.baseUrl}/contractors/${payload.organizationId}/initate_email_verification/`,
      payload.body
    );
  }

  getBannerMessage() {
    return this._http.get(`${this.baseUrl}/admin/app_configs/BANNER_MESSAGE/`);
  }
  getNewPublicBannerMessage() {
    return this._http.get(`${this.baseUrl}/app_configs/BANNER_MESSAGE`);
  }

  getPublicBannerMessage() {
    return this._http.get(`${this.baseUrl}/public/banner_message/`);
  }
}
