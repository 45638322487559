import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppFilter } from '../../models';
import { DatepickerMode } from '@richardlt/ng2-semantic-ui';
@Component({
	selector: 'app-search-filter',
	templateUrl: './search-filter.component.html',
	styleUrls: [ './search-filter.component.scss' ]
})
export class SearchFilterComponent implements OnInit {
	@Input() displayRows: number = 5;
	@Input() pending: boolean;
	@Input() isAdvanceSearch: boolean;
	@Input() isType: boolean;
	@Input() field: any;
	@Input() type: any;

	@Input() filter: AppFilter = new AppFilter();
	@Output() onFilter: EventEmitter<any> = new EventEmitter();
	@Output() onSearch: EventEmitter<any> = new EventEmitter();
	@Output() onSort: EventEmitter<any> = new EventEmitter();
	@Output() onDisplayRows: EventEmitter<number> = new EventEmitter();
	@Output() onYearSelect: EventEmitter<any> = new EventEmitter();
	@Output() onAdvanceSearch: EventEmitter<any> = new EventEmitter();
	@Output() onType: EventEmitter<any> = new EventEmitter();
	@Input() isYear: boolean = false;
	public mode: DatepickerMode = 'year';
	public maxDate = new Date();
	@Input() selectedDate = new Date();
	currentYear: any = this.selectedDate.getFullYear();
	options = [ { label: 'Ascending', value: 'asc' }, { label: 'Descending', value: 'desc' } ];
	sord = 'desc';
	search = new FormControl('');
	filters;
	searchFilter = {
		groupOp: 'AND',
		rules: [
			{
				field: '',
				op: 'cn',
				data: ''
			}
		]
	};
	constructor() {
		this.search.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchText) => {
			this.onSearch.emit(searchText);
		});
	}
	yearChanged(event) {
		if (event) {
			this.currentYear = event.getFullYear();
			this.onYearSelect.emit(event.getFullYear());
		}
	}
	isFieldAsc(field: string) {
		if (this.filter.sord === 'asc' && this.filter.sidx === field) {
			this.filter.sord = 'asc';
			this.onFilter.emit(this.filter);
			return;
		}
	}
	isFieldDesc(field: string) {
		if (this.filter.sord === 'desc' && this.filter.sidx === field) {
			this.filter.sord = 'desc';
			this.onFilter.emit(this.filter);
			return;
		}
	}

	sortByColumn(field: string) {
		return {
			asc: this.isFieldAsc(field),
			desc: this.isFieldDesc(field)
		};
	}

	onDisplayRecords($event) {
		this.onDisplayRows.emit($event);
	}

	onSortRecords(value) {
		this.onSort.emit(value);
	}
	onYearRecords(value) {
		// this.onYearSelect.emit(value);
	}

	ngOnInit(): void {}
}
