<div
	class="button button-container"
  (click)="isClickable ? clickAction.emit($event) : false"
	[ngClass]="{
		small: buttonSize == 'small',
		medium: buttonSize == 'medium',
		large: buttonSize == 'large',
		basic: buttonState == 'basic',
		outline: buttonState == 'outline',
		subdue: buttonState == 'subdue',
		disabled: isDisabled,
		primary: buttonType == 'primary',
		'destructive': buttonType == 'destructive',
		plain: buttonType == 'plain',
		'plain-destructive': buttonType == 'plain-destructive',
		'clickable': isClickable && !hasSuffixIcon && !hasLine,
		'no-line-padding': !hasLine && !hasDropdown && (buttonType !== 'plain' && buttonType !== 'plain-destructive'),
		'line-padding': hasLine && !hasDropdown,
		'small-screen': isSmallScreen,
		black: hasPrefixIcon && buttonType == 'plain',
		hovered: buttonActionState == 'hovered',
		pressed: buttonActionState == 'pressed',
		depressed: buttonActionState == 'depressed',
		'dropdown-btn': hasDropdown,
    'search-btn': hasPrefixIcon
	}"
>
	<div
		*ngIf="hasPrefixIcon"
		[ngClass]="{ 'left-box': hasPrefixIcon , 'clickable':hasPrefixIcon&&hasLine}"
	>
    <img class="search-icon" src="assets/icons/search.svg">
	</div>

	<div
		class="line-divider"
		*ngIf="hasLine && hasPrefixIcon"
	></div>

	<div
		[ngClass]="{
			'left-box': hasSuffixIcon,
			prefix: hasSuffixIcon,
      'dropdown_with-no-line': (hasPrefixIcon || hasSuffixIcon) && !hasLine,
			'right-box': hasPrefixIcon,
			suffix: hasPrefixIcon
		}"
	>
		<span class="text">{{label}}</span>
	</div>
	<div
		class="line-divider"
		*ngIf="hasLine && hasSuffixIcon"
	></div>

	<ng-container *ngIf="hasDropdown && hasSuffixIcon">
		<button
			class="right-box"
      [ngClass]="{
        'clickable':hasSuffixIcon&&hasLine
      }"
		>
			<i class="caret down dropdown icon"></i>
		</button>
	</ng-container>
</div>
