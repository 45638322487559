import { Role } from './role';

export class User {
	appUserId: string;
	username?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	mobileNumber?: string;
	lastLoginDate?: string;
	image?: string;
	middleName?: string;
	roles?: Role[];
	token?: string;
	role: {
		roleId: string;
	};
	organizationId: {
		organizationId: string;
		organizationShortName: string;
		organizationLongName: string;
		organizationTypeId: {
			organizationTypeId: string;
		};
	};
}
