


<div class="panel" fxLayout="row">
    <div fxFlex="grow" style="width: 50%;" appPadding="8px">
        <span style="margin-right: 30px;">
            <div class="ui small icon input" style="margin: 5px;">
                <input type="text" placeholder="Search" style="border: none;"  [formControl]="search" />
                <i class="search icon"></i>
            </div>
        </span>
         <span class="ui small icon input" style="margin:5px">
            <label>Display
            <sui-select class="selection" [ngModel]="filter.records"  style="min-width:4px" placeholder="Choose" (ngModelChange)="onDisplayRecords($event)">
            <sui-select-option value="2"></sui-select-option>
            <sui-select-option value="10"></sui-select-option>
            <sui-select-option value="20"></sui-select-option>
            <sui-select-option value="50"></sui-select-option>
            <sui-select-option value="100"></sui-select-option>
            </sui-select>
            Rows</label>
        </span>
        <!-- <span class="ui small icon input" style="margin:5px" *ngIf="isYear">
            <label>Year
                <button class="ui small right labeled icon button"
                    suiDatepicker
                    [pickerMaxDate]="maxDate"
                    [pickerMode]="mode"
                    [pickerInitialDate]="selectedDate"
                    (pickerSelectedDateChange)="yearChanged($event)">
                    <i class="calendar icon"></i>
                    {{currentYear}}
                </button>
            </label>
        </span> -->
    </div>
    <div fxFlex class="text-right" appPadding="8px" paddingR='10' style="width: 50%; display: flex; justify-content: flex-end; align-items: center;">
        <i appMargin marginR="2" suiPopup [popupInverted]="true" popupPlacement="bottom" popupText="Advanced search" *ngIf="isAdvanceSearch" class="sliders horizontal icon link" (click)="onAdvanceSearch.emit()"></i>

        <a  appMargin marginR="2" *ngIf="isType" class="ui pointing dropdown link item" suiDropdown>
            <i class="link filter icon"></i>
            <div class="left menu" suiDropdownMenu>
                <div class="item" (click)="onSortRecords('desc')">Desc</div>
            </div>
        </a>

        <a class="ui pointing dropdown link item" suiDropdown>
            <!-- <i class="link sort icon"></i> -->
            <i class="sort amount down alternate icon"></i>
            <div class="left menu" suiDropdownMenu>
                <div class="item" (click)="onSortRecords('asc')">Asc</div>
                <div class="item" (click)="onSortRecords('desc')">Desc</div>

            </div>
        </a>
    </div>
</div>