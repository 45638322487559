import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize, DatepickerMode } from '@richardlt/ng2-semantic-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
interface IUploadDocumentModalContext {
	question: string;
	title?: string;
}
@Component({
	selector: 'app-upload-document-modal',
	templateUrl: './upload-document-modal.component.html',
	styleUrls: [ './upload-document-modal.component.scss' ]
})
export class UploadDocumentModalComponent implements OnInit, OnDestroy {
	options = [ 'Active', 'Rejected' ];
	verificationMeans = [ 'Active', 'Rejected' ];
	selectionMethod = [ 'Active', 'Rejected' ];
	procurementType = [ 'Active', 'Rejected' ];
	searchable: boolean = true;
	public mode: DatepickerMode = 'year';
	public maxDate = new Date();
	private unsubscribe$: Subject<void> = new Subject<void>();
	formSubmitAttempt: boolean;
	success: any;
	error: any;
	pending: boolean;

	projectForm: FormGroup;
	constructor(
		@Inject(DOCUMENT) private document,
		private fb: FormBuilder,
		public modal: SuiModal<IUploadDocumentModalContext, void, void>
	) {
		this.projectForm = fb.group({
			referenceNo: [ '' ],
			projectYear: [ '' ],
			procurement_type: [ '' ],
			selection_method: [ '' ],
			means_verfication: [ '' ],
			project_name: [ '' ],
			description: [ '' ],
			output_result: [ '' ]
		});
	}
	isFieldValid(field: string) {
		return (
			(!this.projectForm.get(field).valid && this.projectForm.get(field).touched) ||
			(this.projectForm.get(field).untouched && this.formSubmitAttempt)
		);
	}

	displayFieldCss(field: string) {
		return {
			error: this.isFieldValid(field)
		};
	}

	displayFormCss(field: string) {
		return {
			loading: field
		};
	}
	save() {}
	nullify() {
		this.success = null;
		this.error = null;
	}
	ngOnInit() {
		// this.document.body.classList.remove('panel');
	}
	// s
	ngOnDestroy(): void {
		// this.document.body.classList.add('panel');
	}
}

export class UploadDocumentModal extends ComponentModalConfig<IUploadDocumentModalContext, void, void> {
	constructor(title: string, question: string, size: ModalSize = ModalSize.Small) {
		super(UploadDocumentModalComponent, { title, question });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.transition = 'slide left';
		this.size = size;
		this.isFullScreen = false;
		this.isInverted = false;
	}
}
