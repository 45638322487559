import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlatformModule } from '@angular/cdk/platform';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
// import routing module
import { CoreRoutingModule } from './core-routing.module';

import { AuthenticationService } from './services/authentication';
// import interceptors
import { AuthenticationInterceptor } from './interceptors/authentication';
import { RedirectInterceptor } from './interceptors/redirect';

// import gurds
import { AuthenticationGuard } from './guards/authentication';

// import components
import {
	AdminLayoutComponent,
	ApplicantLayoutComponent,
	HeaderComponent,
	SidebarComponent,
	FooterComponent,
	RedirectComponent,
	ErrorPageComponent,
} from './components';
import { SharedModule } from '../shared/shared.module';
import { environment } from 'src/environments/environment';
import { CustomResponseInterceptor } from './interceptors/custom-response-interceptor.interceptor';
const CORE_MODULES: Array<any> = [
	CommonModule,
	HttpClientModule,
	ReactiveFormsModule,
	RouterModule,
	LayoutModule,
	SharedModule,
	RecaptchaModule,
	RecaptchaFormsModule
];

const CORE_COMPONENTS: Array<any> = [
	AdminLayoutComponent,
	ApplicantLayoutComponent,
	HeaderComponent,
	SidebarComponent,
	FooterComponent,
	RedirectComponent,
	ErrorPageComponent,
];

const CORE_SERVICES: Array<any> = [ AuthenticationService ];

const CORE_GUARDS: Array<any> = [ AuthenticationGuard ];
export function tokenGetter() {
	return localStorage.getItem('jwt');
}

@NgModule({
	imports: [ ...CORE_MODULES, PlatformModule, CoreRoutingModule ],
	declarations: [ ...CORE_COMPONENTS ],
	exports: [ ...CORE_MODULES ]
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				{
					provide: HTTP_INTERCEPTORS,
					useClass: AuthenticationInterceptor,
					multi: true
				},
				{
					provide: RECAPTCHA_SETTINGS,
					useValue: { siteKey: environment.siteKey } as RecaptchaSettings
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: RedirectInterceptor,
					multi: true
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: CustomResponseInterceptor,
					multi: true,
				},
				...CORE_SERVICES,
				...CORE_GUARDS
			]
		};
	}
}
