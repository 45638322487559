import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@richardlt/ng2-semantic-ui';
import { DOCUMENT } from '@angular/common';
interface IConfirmModalContext {
	question: string;
	title?: string;
	confirmText?: string;
	denyText?: string;
	warning?: string;
}
@Component({
	selector: 'app-confirm-modal',
	template: `
		<h3 id="header" class="ui primary header">
			{{ modal.context.title }}
			<i class="link times icon" (click)="modal.deny(undefined)"></i>
		</h3>

		<div class="content">
			<div class="ui basic center aligned segment">
				<p [ngClass]="{mb: !modal.context.warning}">{{ modal.context.question }}</p>
				<p class="mb" *ngIf="modal.context.warning">{{ modal.context.warning }}</p>
				<div class="inline">
					<button class="ui  basic primary button" (click)="modal.deny(undefined)">{{modal.context.denyText || 'No'}}</button>
					<button class="ui  primary button" (click)="modal.approve(undefined)" autofocus>{{modal.context.confirmText || 'Yes'}}</button>
				</div>
			</div>
		</div>
	`,
	styleUrls: [ './confirm-modal.component.scss' ]
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
	constructor(@Inject(DOCUMENT) private document, public modal: SuiModal<IConfirmModalContext, void, void>) {}
	ngOnInit() {
		this.document.body.classList.remove('panel');
	}
	// s
	ngOnDestroy(): void {
		this.document.body.classList.add('panel');
	}
}

export class ConfirmModal extends ComponentModalConfig<IConfirmModalContext, void, void> {
	constructor(title: string, question: string, size: ModalSize = ModalSize.Small, confirmText: string = 'Yes', denyText: string = 'No', warning: string = '') {
		super(ConfirmModalComponent, { title, question, confirmText, denyText, warning });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
		this.isFullScreen = false;
		this.isInverted = false;
	}
}
