import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class DashboardService {
	baseUrl: string = environment.API_SERVER;
	constructor(private _http: HttpClient) {}

	getCompanyRecord(payload) {
		return this._http.get(this.baseUrl + '/dashboard/company_dashboard/');
	}

	getCompanyDetail(payload) {
		return this._http.get(this.baseUrl + '/dashboard/company_details/', { params: payload.params });
	}

	getDashboardVerificationAll(payload) {
		return this._http.get(this.baseUrl + '/dashboard/verifications/', { params: payload.params });
	}

	getDashboardVerificationRegistered(payload) {
		return this._http.get(this.baseUrl + '/dashboard/verifications_registered/', { params: payload.params });
	}
	getCompanyApplication(payload) {
		return this._http.get(this.baseUrl + '/dashboard/applications/');
	}

	getCompanyUser(payload) {
		return this._http.get(this.baseUrl + '/dashboard/users/');
	}
}
