import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[myCurrencyFormatter]' })
export class MyCurrencyFormatterDirective implements OnInit {

	constructor(private elementRef: ElementRef, private currencyPipe: DecimalPipe, private ngControl: NgControl) {
	}

	ngOnInit() {
		this.formatValue()
	}

	@HostListener('focus', [ '$event.target.value' ])
	onFocus() {
		this.formatValue()
	}

	@HostListener('blur', [ '$event.target.value' ])
	onBlur() {
		this.formatValue()
	}

	private formatValue() {
		let value = this.elementRef.nativeElement.value;
    // Remove commas
    value = value.replace(/,/g, '');

    // Format value
    const formattedValue = this.currencyPipe.transform(value, '.2-2')

    // Update ngModel value
    this.ngControl.control.setValue(formattedValue, { emitEvent: false })
	}
}
