	 <h3 class="ui primary header" >{{ modal.context.title }}
	<i class="link times icon" (click)="modal.deny(undefined)"></i>
	</h3>
 <div class="content" >
       <div class="scroll-wrapper ui basic segment" [ngClass]="{'loading':pending}">
      <div *ngIf="success || error" tamsyMargin marginY="10px">
            <sui-message class="success" *ngIf="success">
                      <div class="header">
                        {{success}}
                    </div>
                </sui-message>
                <sui-message class="warning" *ngIf="error">
                      <div class="header">
                    {{error | json}}
                    </div>
                  </sui-message>
            </div>
         <!--Laboratory form  -->
         <div class="ui form" [formGroup]="projectForm">
      <!-- <div class="ui form side-panel" fxLayout="row"
                  fxLayoutAlign='start center'
                  fxLayoutGap='50px'
                  appPadding="15px"
                    appMargin marginB="30px">
                  <svg fxFlex="10%" width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="50" height="50" rx="5" stroke="#999999" stroke-width="2" stroke-dasharray="4 4"/>
                        </svg>

                  <div fxFlex="grow">Upload Picture</div>
                  <div fxFlex>
                  <button  class="ui tiny icon button">
                        <i class="upload icon"></i>
                  </button>
                  </div>
               </div> -->

                  <div class="field" [ngClass]="displayFieldCss('referenceNo')">
                  <label>Document Name</label>
            <input  formControlName="referenceNo" type="text" placeholder="Enter document name">
                  <app-field-error-display [displayError]="isFieldValid('referenceNo')" errorMsg="Please enter document name">
                  </app-field-error-display>
            </div>



            <div class="field" [ngClass]="displayFieldCss('selection_method')">
                  <label>Select Document</label>
                    <input  
                        formControlName="referenceNo" 
                        type="file" 
                        placeholder="Choose document"
                        accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xlx,.xlsx">
                        <app-field-error-display [displayError]="isFieldValid('selection_method')" errorMsg="Please select department">
                  </app-field-error-display>
                        </div>



    </div>

 </div>
 </div>

      <div  class="actions">
    <button class="ui  basic primary button" (click)="modal.deny(undefined)">Cancel</button>
            <button class="ui  primary button" [ngClass]="{'yellow':pending}" [disabled]="pending" (click)="save()" autofocus>Confirm</button>

      </div>


