<div class="ui menu toolbar" paddingX='10' appPadding>
    <!-- <div class="ui container "> -->
    <div class="item header" id="no-borderline">
        <img class="ui image logo" src="./assets/images/naqs-eservices-logo-new.svg"
            popupText="A Data collection and analytics tool">
    </div>
    <div class="right menu">

        <div class="user top right pointing ui dropdown item" suiDropdown>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.07035 7.41406H0V12.617H6.07035V7.41406Z" fill="white" />
                <path d="M12.445 13.7881H7.2421V19.9998H12.445V13.7881Z" fill="white" />
                <path d="M20 7.41406H13.6168V12.617H20V7.41406Z" fill="white" />
                <path d="M12.445 7.41406H7.2421V12.617H12.445V7.41406Z" fill="white" />
                <path d="M12.445 0H7.2421V6.24223H12.445V0Z" fill="white" />
                <path d="M6.07039 13.7883H0V17.7778C0 19.0031 0.996836 20 2.22215 20H6.07043V13.7883H6.07039Z"
                    fill="white" />
                <path d="M17.7779 0H13.6166V6.24215H20V2.22215C20 0.996836 19.0031 0 17.7779 0Z" fill="white" />
                <path d="M13.6166 13.7883V19.9999H17.7778C19.0031 19.9999 20 19.0031 20 17.7778V13.7883H13.6166Z"
                    fill="white" />
                <path d="M2.22215 0C0.996836 0 0 0.996836 0 2.22215V6.24219H6.07039V6.24215V0H2.22215Z" fill="white" />
            </svg>
            <span appMargin marginL="3">Other Apps</span>

            <i class="dropdown icon"></i>
            <div class="menu" suiDropdownMenu>
                <ng-template ngFor let-app [ngForOf]="apps">
                    <!-- href="{{baseUrl}}/identity/continue/?return_url={{app?.applicationHost}}" -->
                    <a class="item" target="_blank" *ngIf="app.applicationHost !== currentHost"
                        href="{{app?.applicationHost}}">
                        <!-- <img class="ui  circular avatar image" 2="/assets/avatar/1.jpg"> -->
                        {{app?.applicationName}}</a>
                </ng-template>
            </div>
        </div>
        <div id="submenudrop " class="ui pointing top right dropdown item" suiDropdown>
            <div style="display: flex; align-items: center; justify-content: center;">
                <div class="msg">
                    <img src="assets/icons/bell-icon.svg">
                    <div class="floating ui red label mini circular">{{totalUnread}}</div>
                </div>
            </div>
            <div class="menu" suiDropdownMenu>
                <div class="ui grid  basic fill  attached segment no-padding">
                    <div id="complaints" class="ui divided selection list"
                        style="max-width: 400px;width: 400px;text-align: center" *ngIf="unRead && unRead.length > 0">

                        <div fxLayout="row" appPadding paddingY="5px" paddingX="2px"
                            fxLayoutAlign="space-between center" *ngFor="let notification of unRead"
                            style="margin-bottom:0px;margin-top:0px;border-bottom: 1px solid #e0dede!important;">
                            <div fxFlex="10%">
                                <span class="ui circular tiny empty red label" *ngIf="notification.read != true"></span>
                                <span>
                                    <i class="large envelope outline icon"
                                        [ngClass]="{'open': notification.read == true}"></i>
                                    <!-- <i class="large email outline icon" style="margin-left: 15px;margin-top: 10px"></i> -->
                                </span>
                            </div>
                            <div fxFlex="grow">
                                <div class="ui message"
                                    style="float: left; padding: 0.2em 0.5em!important; background:none!important; -webkit-box-shadow:none!important; box-shadow:none!important ">
                                    <div class="header" style="font-size: 12px;text-align: left">
                                        <a class="item" routerLink="./notifications">
                                            {{notification?.subject }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20%">
                                {{notification.dateCreated | amTimeAgo}}
                            </div>
                        </div>

                        <div class="item " style="text-align: center">
                            <a [routerLink]="['./notifications']">
                                See All
                            </a>
                        </div>
                    </div>

                    <div class="ui divided selection list" *ngIf="!optionPopulated">
                        <div class="content " style="width: 450px;text-align: center">
                            <h3 style="padding:15%;">
                                <i class="big bell icon"></i>
                                <br> No new notifications
                            </h3>
                        </div>
                        <div class="ui divider" style="margin: 0px" *ngIf="total > 0"></div>
                        <div style="text-align: center" *ngIf="total > 0">
                            <a class="item " [routerLink]="['./notifications']">
                                See All
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>




        <div class="item user">
            {{user?.firstName}} {{user?.lastName}}<br />
            {{user?.role?.roleName}}
        </div>

        <div id="right-borderline" class="ui pointing top right dropdown item" suiDropdown>
            <img class="ui  circular avatar image" [src]="photo">
            <i class="dropdown icon" style="color:#fff"></i>
            <div class="menu" suiDropdownMenu>
                <div class="ui grid " style="width:400px">
                    <div class="five wide column">
                        <div class="ui basic fill  segment">
                            <img class="ui circular image" [src]="photo">
                        </div>
                    </div>
                    <div class="eleven wide column">
                        <div class="ui basic fill segment">
                            <div style="padding-bottom:10px"><strong>{{user?.firstName}} {{user?.lastName}}
                                    {{user?.middleName}}</strong></div>
                            <div style="padding-bottom:10px; white-space: pre-line; overflow-wrap: break-word;">
                                {{user?.appUserEmail}}</div>
                            <a class="ui mini blue button profile-btn" routerLink="/admin/setting/my-profile">My
                                Profile</a>
                        </div>
                    </div>
                    <div class="sixteen wide column actions">
                        <div class="ui basic fill segment" style="background:#f8f8f8!important;">
                            <a class="ui mini button left aligned" routerLink="/admin/setting/account-security">Change
                                password</a>
                            <button class="ui mini button right aligned" style="float:right" (click)="logout()">Sign
                                out</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- </div> -->
</div>