import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication';
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: [ './sidebar.component.scss' ]
})
export class SidebarComponent implements OnInit {
	role: any;
	system: Array<any> = [ 'system_collaborator', 'system_admin' ];
	department: Array<any> = [ 'collaborator' ];
	permissions: Array<any> = [];
	constructor(private authSvc: AuthenticationService) {
		authSvc.user.subscribe((user) => {
			if (user && this.system.indexOf(user.organizationId.organizationTypeId.organizationTypeId) > -1) {
				this.role = 'ADMIN';
			} else if (
				user &&
				this.department.indexOf(user.organizationId.organizationTypeId.organizationTypeId) > -1
			) {
				this.role = 'DEPART';
			}
		});

		if (window.localStorage.getItem('permissions')) {
			let authTokenPermission = JSON.parse(localStorage.getItem('permissions'));
			// this.permissions = JSON.parse(window.localStorage.getItem("permission"));
			this.permissions = authTokenPermission;
		}
	}

	checkPermission(id: string, action: string): boolean {
		if (this.permissions.filter((res) => res.permissionId === id)[0][action] === 'true') {
			return true;
		} else {
			return false;
		}
	}

	ngOnInit(): void {}
}
