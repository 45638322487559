import { Component, OnDestroy, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { debounceTime, takeUntil, distinctUntilChanged, tap, map } from 'rxjs/operators';
import { ChatService, OrganizationService } from 'src/modules/features/admin/services';
import { environment } from 'src/environments/environment';
import { ConfirmModal, ChatDocumentModal } from '../../modals';
import { AppFilter } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { SuiModal, ModalTemplate, TemplateModalConfig, SuiModalService, ModalSize } from '@richardlt/ng2-semantic-ui';
import { FormBuilder, FormControl, Validators, FormGroup, FormControlName } from '@angular/forms';

interface IHeaders {
	field?: string;
	label?: string;
	type?: string;
	choices?: any[];
	op?: string;
	data?: any;
}

interface IDynamicFormContext {
	formStructure: Array<any>;
	formTitle?: string;
	formAction?: string;
	companyTypeOptions?: any;
	prbsOptions?: Array<any>;
}

interface ComplaintMessageView {
	messageId?: string;
	messageBody?: string;
	parentMessageId?: string;
	createdByOrganizationId?: string;
	allowContractorToRespond?: boolean;
	contractorResponded?: boolean;
	dateCreated?: string;
	createdBy?: string;
	complaintId?: string;
	resolvedPath?: string;
	level?: number;
	username?: string;
	firstName?: string;
	lastName?: string;
	organizationShortName?: string;
	organizationLongName?: string;
	logoPath?: string;
	organizationTypeId?: string;
	fileName: string;
	filePath: string;
	fileDescription: string;
	extension: string;
	extensionGroup: string;
}

@Component({
	selector: 'app-chat-nested-message',
	templateUrl: './comment.html',
	styleUrls: [ './comment.scss' ]
})
export class CommentNestedComponent implements OnInit, OnDestroy {
	@Input() complaintId: string;
	@Input() parentMessageId: string;
	@Output() reply: EventEmitter<any> = new EventEmitter();
	successMessage;
	messageIndex;
	collapse: boolean = false;
	replyBlock;
	success: boolean;
	error: any;
	organization: any;
	user: any;
	organizationId: string;
	messages: Array<any>;
	pending: boolean;
	total;
	page;
	records;

	replyForm: FormGroup;
	messageParams: AppFilter;
	searchMessagesFilter = {
		groupOp: 'AND',
		rules: [
			{
				field: 'parentMessageId',
				op: 'eq',
				data: ''
			}
		]
	};

	baseUrl;
	appUserImage: any;
	private unsubscribe$: Subject<void> = new Subject<void>();
	constructor(
		// private msgSvc: MessageService,
		// private commentSvc: CommentsService,
		private _complaintsSrv: ChatService,
		private fb: FormBuilder
	) {
		this.messageParams = new AppFilter({ sidx: 'dateCreated', rows: 15, sord: 'desc' });
		this.baseUrl = environment.API_SERVER;

		this.replyForm = fb.group({
			comment: [ '', Validators.required ],
			equipmentName: [ '' ],
			to: [ '' ],
			documentId: [ '' ]
		});
		this.organization = JSON.parse(window.localStorage.getItem('organization'));
		this.organizationId = window.localStorage.getItem('organizationId');
		this.user = JSON.parse(window.localStorage.getItem('user'));
		// JSON.parse(window.localStorage.getItem('userData'))['appUser'];
		if (this.user && this.user.image) {
			this.user.image = `${this.baseUrl}/public/image/?media=${this.user.image}`;
		} else {
			this.user.image = 'assets/avatar/1.jpg';
		}

		this._complaintsSrv.getRefreshMessages().pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
			this.getMessages(this.messageParams.page);
		});
	}
	ngOnInit() {
		this.messageParams = new AppFilter({ sidx: 'messageId', rows: 15 });
		this.searchMessagesFilter.rules[0].data = this.parentMessageId;
		this.messageParams.filters = JSON.stringify(this.searchMessagesFilter);
		this.getMessages(1);
		// console.log(this.complaintId);
		// console.log(this.parentMessageId);
	}
	onScrollDown(eve) {}
	onScrollUp(eve) {}
	getMessages(page) {
		this.replyBlock = null;
		this.messageParams.page = page;
		this.messageParams.sord = 'asc';
		this.pending = true;
		this._complaintsSrv
			.getAllComplaintMessagesView({
				organizationId: this.organizationId,
				complaintId: this.complaintId,
				params: this.messageParams
			})
			.pipe(
				tap((res) => {
					this.total = +res['records'];
					this.page = page;
					this.records = +this.messageParams.rows;
				}),
				map((res: any) => res.rows),
				map((res: any) => {
					res.forEach((watcher) => {
						if (watcher.appUserImage) {
							watcher.appUserImage = `${this.baseUrl}/public/image/?media=${watcher.appUserImage}`;
						} else {
							watcher.appUserImage = 'assets/avatar/1.jpg';
						}
					});
					return res;
				}),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				(res: any[]) => {
					this.pending = false;
					this.messages = res;
					console.log(this.messages);
				},
				(error) => {
					this.pending = false;
					this.error = error;
				}
			);
	}
	// monitor approval
	approve(event) {
		let complaintApprovalId, status;
		complaintApprovalId = event.complaintApprovalId;
		status = event.status;
		this.error = null;
		this.success = null;
		this.pending = true;
		this._complaintsSrv
			.approvalComplaintMonitoring({
				organizationId: this.organizationId,
				complaintApprovalId: complaintApprovalId,
				status: status
			})
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(
				(res) => {
					this.pending = false;
					if (status === 'APPROVED') {
						this.successMessage = 'Message approved successfully';
					} else {
						this.successMessage = 'Message rejected succssfully';
					}
					// this._complaintsSrv.setRefreshMessages('');
					this.messages = [];
					this.getMessages(this.messageParams.page);
				},
				(error) => {
					this.pending = false;
					if (status === 'APPROVED') {
						this.error = 'Failed to approve message';
					} else {
						this.error = 'Failed to reject message';
					}
				}
			);
	}
	onReply(event) {}
	showReplyForm(form) {
		// this.replyBlock = form;
		this.reply.emit(form);
	}
	viewDoc(msg) {
		let url = `${this.baseUrl}/public/image/?media=${msg.documentId}`;
		// var filename = `${this._activity.name.split(" ").join("_")}.xlsx`;
		// 	var element = document.createElement("a");
		// 	let url = `${this.baseUrl}/activity-structure-versions/${this.currentStructure.activityStructureVersionId}/template/?to_filename=${this.currentStructure.name}_${filename}`;
		// 	window.open(url, "_blank");
	}
	uploadDoc() {}
	ngOnDestroy() {}
}
