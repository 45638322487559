import { Injectable } from '@angular/core';
// import { Token } from '../../interfaces/token.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from 'src/modules/shared/models/user';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
  activityTemplate: BehaviorSubject<any> = new BehaviorSubject(null);
  userSubject: BehaviorSubject<User>;
  organization: BehaviorSubject<any>;
  public user: Observable<User>;
  token: any;
  apiUrl: string = environment.API_SERVER;

  constructor(private _httpClient: HttpClient, private router: Router) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('user'))
        ? JSON.parse(localStorage.getItem('user'))
        : null
    );
    this.organization = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('organization'))
        ? JSON.parse(localStorage.getItem('organization'))
        : null
    );
    this.user = this.userSubject.asObservable();
    this.token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : null;
  }
  setRefreshStorage(chart): void {
    console.log('refres');
    this.userSubject.next(
      JSON.parse(localStorage.getItem('user'))
        ? JSON.parse(localStorage.getItem('user'))
        : null
    );
    this.organization.next(
      JSON.parse(localStorage.getItem('organization'))
        ? JSON.parse(localStorage.getItem('organization'))
        : null
    );
    // console.log();
    // this.user.
  }

  setActivityTemplate(activit) {
    this.activityTemplate.next(activit);
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  setUserValue(user) {
    this.userSubject.next(user);
  }
  // mock login
  public mockLogin(body): Observable<User> {
    return this._httpClient
      .post<any>(this.apiUrl + '/auth/mobile_login/', body)
      .pipe(
        map((user) => {
          localStorage.setItem('authentication', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  public logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    localStorage.removeItem('jwt');
    this.userSubject.next(null);
    this.router.navigate(['/home-page']);
  }

  //Get token from e-service and validate it
  public validateTokenAndRedirect(token): Observable<any> {
    // console.log('Validate');
    return this._httpClient.get(
      `http://apps.showcase.com.ng/api-server/identity/validate/?token=${token}`,
      this.getAuthHeaderOption()
    );
  }

  //Header for authenticated pages
  public getAuthHeaderOption(contentType = 'application/json'): any {
    const headers = new HttpHeaders({
      'Content-Type': contentType,
      authorization: 'Bearer ' + this.token,
      'Access-Control-Allow-Origin': '*',
    });
    return { headers: headers };
  }

  getToken() {
    if (!this.token) {
      this.token = this.userValue['token'];
    }
    return this.token;
  }

  setToken(token) {
    token.value = 'Bearer ' + token.value;
    this.token = token;
    localStorage.setItem('authentication', JSON.stringify(token));
  }

  setClientId(result) {
    localStorage.setItem('client_id', result);
  }

  getAuthorizationHeader() {
    return this.userSubject.value !== null ? true : false;
  }

  login(payload) {
    return this._httpClient.get(
      `${this.apiUrl}/auth/login/?token=${payload.token}`
    );
  }

  forgetToken() {
    this.token = null;
    localStorage.removeItem('authentication');
  }
}
