import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	ViewEncapsulation,
	AfterViewInit,
	OnDestroy,
	Inject,
	Renderer2
} from '@angular/core';
// import {v4 as uuidv4} from "uuid";
import { DOCUMENT } from '@angular/common';
import { SuiModal, ComponentModalConfig, ModalSize } from '@richardlt/ng2-semantic-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppFilter } from '../../models';
import { Subject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
declare var $: any;
interface IDynamicDataDetailModalContext {
	title?: string;
	structure?: any;
	isEdit: boolean;
}

@Component({
	selector: 'app-dynamic-data-detail-modal',
	templateUrl: './new-activity-modal.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [ './new-activity-modal.component.scss' ]
})
export class DynamicDataDetailModalComponent implements OnInit, OnDestroy {
	@ViewChild('detail', { static: true })
	MyDOMElement: ElementRef;
	public maxDate = new Date();
	private unsubscribe$: Subject<void> = new Subject<void>();
	formSubmitAttempt: boolean;
	pending: boolean;
	structure: any;
	columns: Array<any> = [];
	selectForEdit: any;
	rawDetail: any;
	baseUrl: string;
	constructor(
		private renderer: Renderer2,
		public modal: SuiModal<IDynamicDataDetailModalContext, void, void>,
		@Inject(DOCUMENT) private document
	) {
		this.structure = modal.context.structure.structure;
		this.baseUrl = modal.context.structure.baseUrl;
		this.columns = [ ...modal.context.structure.tableColumns ];
		this.rawDetail = modal.context.structure.activityData;
		this.selectForEdit = modal.context.structure.activityDataId;
	}
	ngOnInit() {
		this.onView();
	}

	onView() {
		var content = "<table class='ui definition table'>";
		console.log(this.rawDetail);
		console.log(this.columns);
		console.log(this.structure);
		// this.selectForEdit = id;
		let rawDetail = this.rawDetail;
		this.columns.push({ name: 'dateCreated', type: 'custom' });
		this.columns.push({ name: 'createdBy', type: 'custom' });

		let columns = this.columns;
		let unFormatedColumns = this.columns;
		let phototype = this.columns.filter((x) => x.type === 'photo' || x.type === 'image' || x.type === 'file');
		let poho: Array<any> = [];
		if (phototype.length > 1) {
			for (let i = 0; i < phototype.length; i++) {
				poho.push(phototype[i]['name']);
			}
		} else if (phototype.length === 1) {
			poho.push(phototype[0]['name']);
		}
		// let detailx = JSON.parse(rawDetail[0].formData);
		let detailx = rawDetail[0];

		delete detailx.meta;
		delete detailx.end;
		delete detailx.start;
		delete detailx.hasFiles;
		delete detailx.activityDataId;
		delete detailx.instanceID;
		// loop through each of the properties in the return map data details
		for (let key in detailx) {
			// check the form struct and pick out the question that matches the key by comparing the form struct name prop
			let column = this.columns.filter((x) => x.name === key);
			console.log(column);
			let actualname = key;
			let realable;

			// if the object has a label use the label if not use our key
			if (column.length > 0 && column[0].hasOwnProperty('label')) {
				realable = column[0]['label'];
			} else {
				realable = key;
			}
			if (poho.indexOf(key) > -1 || key === 'image_test') {
				let self = this;
				var photoArray = [];
				let arr = `<tr><td>${realable}</td><td>`;
				$.each(detailx[key].split(' '), function(index: any, photo: any) {
					if (photo !== '') {
						arr += `<a class="fancybox" rel="${self.selectForEdit}" href="${self.baseUrl}/public/image/?media=${photo}"	>
						<img  alt='${photo.split('/').pop()}'
						style="width:50px; height:50px" src="${self.baseUrl}/public/image/?media=${photo}"></a>`;
					}
				});
				arr += `</td></tr>`;
				content += arr;
				// @ts-ignore
				$('.fancybox').fancybox();
			} else if (
				detailx[key] !== null &&
				'object' === typeof detailx[key] &&
				column[0]['type'] !== 'select all that apply'
			) {
				let items = detailx[key];

				if (!Array.isArray(detailx[key])) {
					items = [ detailx[key] ];
				}
				console.log(items);
				const lnum = Object.keys(items).length;
				content += `<tr>
                    <td>${realable}</td>
                      <td>
                     <table>

					<tr><td>
					<sui-accordion class="styled fluid">
					<sui-accordion-panel >
						<div title>
							<i class="dropdown icon"></i>
						     ${lnum} records
						</div>
						<div content>
                        `;
				// prepare children table headers
				content += "<table class='table table-striped table-bordered table-condensed'>";
				let childColumns = findChildrenHeads(this.columns, key);
				// console.log;
				content += `<tr>`;
				// console.log(childColumns)
				for (var xc in childColumns) {
					content += `<td>${childColumns[xc].label}</td>`;
				}
				content += `</tr>`;
				// loop through the children items data
				for (let g = 0; g < items.length; g++) {
					let eachItemObj = items[g];
					content += `<tr>`;
					for (var xx in childColumns) {
						let field = childColumns[xx]['name'];
						if (
							!(childColumns[xx]['type'] == 'photo' || childColumns[xx]['type'] == 'image') &&
							eachItemObj.hasOwnProperty(field)
						) {
							content += `<td>${eachItemObj[field]}</td>`;
						} else if (
							(childColumns[xx]['type'] == 'photo' || childColumns[xx]['type'] == 'image') &&
							eachItemObj.hasOwnProperty(field)
						) {
							let self = this;
							let arr = `<td>`;
							$.each(eachItemObj[field].split(' '), function(index, photo) {
								if (photo !== '') {
									arr += `<a class="fancybox" rel="${self.selectForEdit}" href="${self.baseUrl}/public/image/?media=${photo}"><img style="width:50px; height:50px" src="${self.baseUrl}/public/image/?media=${photo}"></a>`;
								}
							});
							arr += `</td>`;
							content += arr;
							// @ts-ignore
							$('.fancybox').fancybox();
						} else {
							content += `<td></td>`;
						}
					}
					content += `</tr>`;
				}
				content += `</table>
                    		</div>
					</sui-accordion-panel>
				</sui-accordion>
					</td></tr>
                    </table>
                     </td></tr>`;
			} else if (column[0] && column[0]['type'] == 'select all that apply') {
				content += `<tr><td>${realable}</td><td> ${/[,]/.test(detailx[key])
					? detailx[key].join(',')
					: detailx[key]} </td></tr>`;
			} else if (column[0] && column[0]['name'] == 'dateCreated') {
				content += `<tr><td>${realable}</td><td> ${detailx[key].split('T')[0]} </td></tr>`;
			} else if (column[0] && column[0]['name'] == 'createdBy') {
				let createdBy = JSON.parse(detailx[key]);
				content += `<tr><td>${realable}</td><td> ${createdBy['organizationName']} </td></tr>`;
			} else {
				content += `<tr><td>${realable}</td><td> ${detailx[key]} </td></tr>`;
			}
		}
		this.pending = false;
		content += '</table>';
		this.MyDOMElement.nativeElement.innerHTML = content;
		$('.fancybox').fancybox();
		// $('#feature-info').html(content);
		function findChildrenHeads(columns, rename) {
			console.log(rename);
			let arrx: Array<any> = [];
			let arrayFlatten = function(arr: any) {
				for (let key in arr) {
					// if (arr[key]['name'].trim() === rename.trim()) {
					//     let items = arr[key]["children"];
					//     console.log(rename, arr[key]['name'])
					//     for (var ckey in items) {
					//         if ("undefined" === typeof items[ckey]['label']) {
					//             arrx.push({ name: items[ckey]['name'], label: items[ckey]['name'], type: items[ckey]['type'] })
					//         } else {
					//             arrx.push({ name: items[ckey]['name'], label: items[ckey]['label'], type: items[ckey]['type'] })
					//         }
					//     }
					// }

					if (arr[key].type === 'group') {
						arrayFlatten(arr[key].children);
					} else if (arr[key]['name'].trim() === rename.trim()) {
						// console.log(rename)
						// console.log(arr[key])
						let items = arr[key]['children'];
						for (var ckey in items) {
							if ('undefined' === typeof items[ckey]['label']) {
								arrx.push({
									name: items[ckey]['name'],
									label: items[ckey]['name'],
									type: items[ckey]['type']
								});
							} else {
								arrx.push({
									name: items[ckey]['name'],
									label: items[ckey]['label'],
									type: items[ckey]['type']
								});
							}
						}
					}
				}
			};
			arrayFlatten(columns);
			console.log('returned child columns', arrx);
			return arrx;
		}
	}
	search() {
		this.modal.approve(null);
	}
	ngOnDestroy(): void {
		// unsubscribe all subsciptons
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		// this.document.body.classList.remove('panel');
	}
}

export class DynamicDataDetailModal extends ComponentModalConfig<IDynamicDataDetailModalContext, void, void> {
	constructor(title, structure: any, isEdit, size: ModalSize = ModalSize.Small) {
		super(DynamicDataDetailModalComponent, { title, structure, isEdit });

		this.isClosable = false;
		this.transitionDuration = 400;
		this.size = size;
		this.isFullScreen = false;
		this.isInverted = false;
		this.transition = 'slide left';
		this.mustScroll = true;
		// this.
		// this.
	}
}
