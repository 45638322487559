import { Directive, Input, SimpleChanges, ElementRef } from '@angular/core';

@Directive({
	selector: '[appMargin]'
})
export class MarginDirective {
	// tslint:disable-next-line:no-input-rename
	@Input('appMargin') margin: any;
	@Input() marginX: any;
	@Input() marginY: any;
	@Input() marginL: any;
	@Input() marginR: any;
	@Input() marginT: any;
	@Input() marginB: any;
	get left() {
		return this._parseValue(this._getValue(this.marginL, this.marginX, this.margin));
	}
	get right() {
		return this._parseValue(this._getValue(this.marginR, this.marginX, this.margin));
	}
	get top() {
		return this._parseValue(this._getValue(this.marginT, this.marginY, this.margin));
	}
	get bottom() {
		return this._parseValue(this._getValue(this.marginB, this.marginY, this.margin));
	}
	private readonly _baseSpacing = 0.25;
	constructor(private el: ElementRef) {}

	ngOnInit() {
		this._setElementMargin(this.el);
	}
	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.margin ||
			changes.marginX ||
			changes.marginY ||
			changes.marginL ||
			changes.marginR ||
			changes.marginT ||
			changes.marginB
		) {
			if (this.el.nativeElement) {
				this._setElementMargin(this.el);
			}
		}
	}
	private _setElementMargin(el: ElementRef) {
		const left = this.left;
		const right = this.right;
		const top = this.top;
		const bottom = this.bottom;
		if (left) {
			el.nativeElement.style.marginLeft = left;
		}
		if (right) {
			el.nativeElement.style.marginRight = right;
		}
		if (top) {
			el.nativeElement.style.marginTop = top;
		}
		if (bottom) {
			el.nativeElement.style.marginBottom = bottom;
		}
	}
	private _parseValue(value: any): string {
		if (!value && value !== 0) {
			return value;
		}
		if (this._isInt(value)) {
			return this._baseSpacing * value + 'rem';
		}
		return value;
	}
	private _getValue(priority1: any, priority2: any, priority3: any) {
		if (priority1 || priority1 === 0) {
			return priority1;
		}
		if (priority2 || priority2 === 0) {
			return priority2;
		}
		return priority3;
	}
	private _isInt(value) {
		return !isNaN(value) && ((x) => (x | 0) === x)(parseFloat(value));
	}
}
