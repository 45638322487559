import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DepartmentService {
	baseUrl: string = environment.API_SERVER;
	constructor(private _http: HttpClient) {}

	getUnAssignDepartmentUsers(payload) {
		return this._http.get(this.baseUrl + '/organizations/unassigned/department-users/', { params: payload.params });
	}

	getDepartment(payload) {
		return this._http.get(this.baseUrl + '/admin/organizations/', { params: payload.params });
	}

	getDepartmentApplication(payload) {
		return this._http.get(this.baseUrl + `/organizations/${payload.organizationId}/applications/`, {
			params: payload.params
		});
	}
	assignDepartmentApplication(payload) {
		return this._http.put(
			this.baseUrl +
				`/admin/collaborators/${payload.organizationId}/assign_department/?applicationId=${payload.applicationId}`,
			payload.body
		);
	}

	inviteNewUser(payload) {
		return this._http.post(
			this.baseUrl + `/admin/organizations/${payload.organizationId}/users/new/`,
			payload.body
		);
	}
	inviteExistingUser(payload) {
		return this._http.post(
			this.baseUrl + `/admin/organizations/${payload.organizationId}/users/existing/`,
			payload.body
		);
	}

	addDepartment(payload) {
		return this._http.post(this.baseUrl + '/admin/organizations/', payload.body);
	}

	updateDepartment(payload) {
		return this._http.put(`${this.baseUrl}/admin/organizations/${payload.id}/`, payload.body);
	}

	deleteDepartment(payload) {
		return this._http.delete(`${this.baseUrl}/admin/organizations/${payload.id}/`);
	}
}
