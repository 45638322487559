import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('jwt');
    const isApiUrl = request.url.startsWith(environment.API_SERVER);
    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
      //   console.log('Request: ', request);
      //   console.log('Token: ', token);
      //   console.log('isApiUrl: ', isApiUrl);
    }

    return next.handle(request);
  }
}
