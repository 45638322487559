import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UnitService {
	baseUrl: string = environment.API_SERVER;
	constructor(private _http: HttpClient) {}

	deleteUnitApplication(payload) {
		return this._http.delete(
			this.baseUrl +
				`/organization/${payload.organizationId}/unit/${payload.organizationUnitId}/application/${payload.applicationId}/`
		);
	}

	getUnitApplication(payload) {
		return this._http.get(this.baseUrl + `/organization_units/${payload.id}/applications/`, {
			params: payload.params
		});
	}
	editUnitApplication(payload) {
		return this._http.put(
			this.baseUrl + `/organization_units/${payload.id}/applications/${payload.applicationId}/`,
			payload.body
		);
	}

	addUnitApplication(payload) {
		return this._http.post(
			this.baseUrl + `/organization_units/${payload.id}/applications/${payload.applicationId}/`,
			payload.body
		);
	}

	getUnit(payload) {
		return this._http.get(this.baseUrl + `/organization/${payload.organizationId}/units/`, {
			params: payload.params
		});
	}

	addUnit(payload) {
		console.log('add');
		return this._http.post(this.baseUrl + `/organization/${payload.organizationId}/unit/`, payload.body);
	}

	updateUnit(payload) {
		console.log('update');
		return this._http.put(
			`${this.baseUrl}/organization/${payload.organizationId}/unit/${payload.id}/`,
			payload.body
		);
	}

	deleteUnit(payload) {
		return this._http.delete(`${this.baseUrl}/organization/${payload.organizationId}/unit/${payload.id}/`);
	}

	getNoUnitUsers(payload) {
		return this._http.get(this.baseUrl + `/organization/${payload.organizationId}/unassigned-unit-users/`, {
			params: payload.params
		});
	}

	getUnitUsers(payload) {
		return this._http.get(
			this.baseUrl + `/organization/${payload.organizationId}/unit/${payload.organizationUnitId}/users/`,
			{
				params: payload.params
			}
		);
	}

	addUnitUser(payload) {
		return this._http.post(
			this.baseUrl + `/organization/${payload.organizationId}/unit/${payload.organizationUnitId}/users/`,
			payload.body
		);
	}

	deleteUnitUser(payload) {
		return this._http.delete(
			this.baseUrl +
				`/organization/${payload.organizationId}/unit/${payload.organizationUnitId}/user/${payload.appUserId}/`
		);
	}
}
