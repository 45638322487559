import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize, DatepickerMode } from '@richardlt/ng2-semantic-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { CommonServices } from '../../services';
interface ITwoFactorModalContext {
	data: any;
	title?: string;
	isEdit?: boolean;
}
@Component({
	selector: 'app-twoFactor-new-modal',
	templateUrl: './twofactor-modal.component.html',
	styleUrls: [ './twofactor-modal.component.scss' ]
})
export class TwoFactorModalComponent implements OnInit, OnDestroy {
	searchable: boolean = true;
	public mode: DatepickerMode = 'year';
	public maxDate = new Date();
	private unsubscribe$: Subject<void> = new Subject<void>();
	formSubmitAttempt: boolean;
	success: any;
	error: any;
	pending: boolean;
	twoFactorForm: FormGroup;
	appUserId: string;
	barCode: any;
	isActive: boolean;
	screen = '';
	eRadio = 'app';
	constructor(
		@Inject(DOCUMENT) private document,
		private fb: FormBuilder,
		public modal: SuiModal<ITwoFactorModalContext, void, void>,
		private commonSvc: CommonServices,
		private sanitizer: DomSanitizer
	) {
		this.appUserId = JSON.parse(window.localStorage.getItem('user'))['appUserId'];
		this.twoFactorForm = fb.group({
			referenceNo: [ '' ],
			twoFactorYear: [ '' ],
			procurement_type: [ '' ],
			selection_method: [ '' ],
			means_verfication: [ '' ],
			twoFactor_name: [ '' ],
			description: [ '' ],
			output_result: [ '' ]
		});
	}
	isFieldValid(field: string) {
		return (
			(!this.twoFactorForm.get(field).valid && this.twoFactorForm.get(field).touched) ||
			(this.twoFactorForm.get(field).untouched && this.formSubmitAttempt)
		);
	}

	displayFieldCss(field: string) {
		return {
			error: this.isFieldValid(field)
		};
	}

	displayFormCss(field: string) {
		return {
			loading: field
		};
	}
	submit() {
		if (!this.isActive) {
			this.save();
		} else {
			this.validateCode();
		}
	}
	save() {
		this.error = null;
		if (this.eRadio == 'app') {
			this.pending = true;
			this.commonSvc.initiateTwoFactor({ appUserId: this.appUserId }).subscribe(
				(res) => {
					this.pending = false;
					this.barCode = this.sanitizer.bypassSecurityTrustResourceUrl(res['url']);
					this.isActive = true;
				},
				(error) => {
					this.pending = false;
					this.error = error.error ? error.error : error;
				}
			);
			// this.modal.approve(null);
		} else {
			this.error = 'SMS option is currently not supported';
		}
	}

	validateCode() {
		if (this.twoFactorForm.valid) {
			this.error = null;
			this.pending = true;
			this.commonSvc
				.validateTwoFactor({
					appUserId: this.appUserId,
					params: { otp: this.twoFactorForm.value.twoFactor_name }
				})
				.subscribe(
					(res) => {
						this.pending = false;
						if (res) {
							this.enable();
						} else {
							this.error = 'Invalid otp code';
						}
					},
					(error) => {
						this.pending = false;
						this.error = error.error ? error.error : error;
					}
				);
		} else {
			this.error = "Enter code";
		}
	}
	enable() {
		this.pending = true;
		this.commonSvc
			.enableTwoFactor({ appUserId: this.appUserId, otp: this.twoFactorForm.value.twoFactor_name })
			.subscribe(
				(res) => {
					this.pending = false;
					this.success = '2-Factor authentication enabled successfully';
					setTimeout(() => {
						this.modal.approve(null);
					}, 2000);
				},
				(error) => {
					this.pending = false;
					this.error = error.error ? error.error : error;
				}
			);
	}
	nullify() {
		this.success = null;
		this.error = null;
	}
	ngOnInit() {
		// this.document.body.classList.remove('panel');
	}
	// s
	ngOnDestroy(): void {
		// this.document.body.classList.add('panel');
	}
}

export class TwoFactorModal extends ComponentModalConfig<ITwoFactorModalContext, void, void> {
	constructor(title: string, data: any, isEdit: boolean, size: ModalSize = ModalSize.Small) {
		super(TwoFactorModalComponent, { title, data, isEdit });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.transition = 'slide left';
		this.size = size;
		this.isFullScreen = false;
		this.isInverted = false;
	}
}
