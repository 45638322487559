import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTabset } from './components/tabset';
import { AppTabHeader } from './directives/tab-header';
import { AppTabContent } from './directives/tab-content';

@NgModule({
	imports: [ CommonModule ],
	declarations: [ AppTabset, AppTabHeader, AppTabContent ],
	exports: [ AppTabset, AppTabHeader, AppTabContent ]
})
export class AppTabsModule {}
