
        <div class="comment" appMargin marginB="10">
                     <div fxLayout="row" fxLayoutAlign="end" appMargin marginB="0" *ngIf="user.username == (msg.createdBy | createdBy) && !isReply">
                        <div fxFlex="grow" class="text-right">
                                <h3 appMargin marginB="0">{{msg?.createdBy | createdBy}}  -  {{msg?.dateCreated | date:'d, MMMM y, |h:mm a'}}</h3>
                                <div class="secondaryText"></div>
                                  <div appMargin marginL="10" fxFlex="10%" fxFlexAlign="end center" *ngIf="!isReply">
                                <div class="secondaryText link" (click)="showReplyForm(msg)">Reply</div>
                            </div>
                            </div>
                        <div  fxFlex="10%" fxFlexAlign="center" class="text-center">
                                <img  class="ui avatar image" *ngIf="!msg?.appUserImage" src="/assets/avatar/1.jpg">
                                <img class="ui avatar image" *ngIf="msg?.appUserImage" [src]="msg.appUserImage">
                        </div>

                    </div>

                  <div fxLayout="row" appMargin marginB="0" *ngIf="user.username !== (msg.createdBy | createdBy)|| isReply">
                        <div  fxFlex="7%" fxFlexAlign="center">
                                <img  class="ui avatar image" *ngIf="!msg?.appUserImage" src="/assets/avatar/1.jpg">
                                <img class="ui avatar image" *ngIf="msg?.appUserImage" [src]="msg.appUserImage">
                        </div>
                        <div fxFlex="grow">
                                <h3 appMargin marginB="0">{{msg?.createdBy | createdBy}}  -  {{msg?.dateCreated | date:'d, MMMM y, |h:mm a'}}</h3>
                                <div class="secondaryText"></div>
                                  <div appMargin marginL="10"  fxFlex="10%" fxFlexAlign="end center" *ngIf="!isReply">
                                <div class="secondaryText link" (click)="showReplyForm(msg)">Reply</div>
                            </div>
                            </div>
                    </div>
                        <div fxLayout="row" *ngIf="user.username ==(msg.createdBy | createdBy)">
                                   <div fxFlex="25%" >
                                </div>
                                <div fxFlex="85%" class="content-area" appMargin marginR="5">
                                         <div *ngIf="msg.parentCommentId" class="ui segment" id="reply-block">
                                                <div fxLayout="row" appMargin marginB="2" >
                                                        <div fxFlex="grow">
                                                                <div>{{msg?.parentCommentId?.createdBy | createdBy}}</div>
                                                                <div class="secondaryText">{{msg?.parentCommentId?.dateCreated | date:'d, MMMM y, |h:mm'}}</div>
                                                        </div>
                                                </div>
                                                        <quill-view-html [content]="msg.parentCommentMetadata | parentMeta" theme="snow"></quill-view-html>
                                                        <!-- <p>  {{msg?.parentCommentId?.comment}} </p> -->
                                                </div>
                                <quill-view-html [content]="msg.comment" theme="snow"></quill-view-html>
                                </div>
                        </div>
                        <div fxLayout="row" *ngIf="user.username !== (msg.createdBy | createdBy)">
                                <div fxFlex="80%" class="content-area">
                                         <div *ngIf="msg.parentCommentId" class="ui segment" id="reply-block">
                                                <div fxLayout="row" appMargin marginB="2" >
                                                        <div fxFlex="grow">
                                                                <div>{{msg?.parentCommentId?.createdBy | createdBy}}</div>
                                                                <div class="secondaryText">{{msg?.parentCommentId?.dateCreated | date:'d, MMMM y, |h:mm'}}</div>
                                                        </div>
                                                </div>
                                                        <quill-view-html [content]="msg.parentCommentMetadata | parentMeta" theme="snow"></quill-view-html>
                                                        <!-- <p>  {{msg?.parentCommentId?.comment}} </p> -->
                                                </div>
                                <quill-view-html [content]="msg.comment" theme="snow"></quill-view-html>
                                </div>
                                <div fxFlex="30%" >
                                </div>
                        </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between" >
                                <div fxFlex  >
                                <a   *ngIf="msg.documentMetadata"  target="_blank" href="{{baseUrl}}/public/image/?media={{msg?.documentMetadata.documentPath}}"
                                        target="_blank"
                                >{{msg?.documentMetadata?.documentName}}
                                                <i class="file alternate outline icon"></i>
                                </a>
                                </div>
                   </div>
        </div>