import {Pipe, Injectable} from "@angular/core";

@Pipe({
	name: "mapFilter",
	pure: false,
})
export class MapPipe {
	transform(object: any[], filter: string) {
		if (!filter || filter === "") {
			return object;
		} else {
			return object.filter((item) => -1 < item.name.toLowerCase().indexOf(filter.toLowerCase()));
		}
	}
}

// let equipmentFields=
// {
//   "equipmentFields": {
//     "details": {
// 	"equipment_name":'vallue"
// 	},
// 	"some other section":{
// 	"key":'vallue"
// 	}
// },
//   "equipmentCategoryId": "string",
//   "manufacturerGlobalId": "string"
// }
