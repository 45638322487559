import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'createdBy'
})
export class CreatedByPipe implements PipeTransform {
	transform(value: string): any {
		let multiplier = {};
		if (value) {
			multiplier = JSON.parse(value);
		}
		return multiplier['name'] ? multiplier['name'] : multiplier['appUserId'];
	}
}
