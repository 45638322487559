// import { AppUser } from './appuser.model';

export interface Notification {
  notificationId?: number;
  read?: boolean;
  active?: boolean;
  subject?: string;
  body?: string;
  dateCreated?: string;
  dateModified?: string;
  modifiedBy?: string;
  appUserId?: any;
  data?: string;
}
