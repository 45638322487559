import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'createdByOrg'
})
export class CreatedByOrgPipe implements PipeTransform {
	transform(value: string): any {
		let multiplier = {};
		if (value) {
			multiplier = JSON.parse(value);
		}
		return multiplier['appUserId'];
	}
}
